<template>
  <v-dialog
      v-model="visible"
      persistent
      max-width="450"
      overlay-color="black"
      overlay-opacity="0.64"
  >
    <v-card>
      <v-card-title>
        <slot>
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="30" cy="30" r="30" fill="#F8E3E2"/>
            <path d="M45.2968 36.6395L33.4527 16.1249C31.9183 13.4672 28.0821 13.4672 26.5477 16.1249L14.7036 36.6395C13.1691 39.2973 15.0872 42.6194 18.1561 42.6194H41.8443C44.9132 42.6194 46.8312 39.2973 45.2968 36.6395Z" stroke="#C83B37" stroke-width="3"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M30.0001 31.3676C29.1625 31.3676 28.4834 30.6886 28.4834 29.8509V24.6686C28.4834 23.8309 29.1625 23.1519 30.0001 23.1519V23.1519C30.8378 23.1519 31.5168 23.8309 31.5168 24.6686V29.8509C31.5168 30.6886 30.8378 31.3676 30.0001 31.3676V31.3676Z" fill="#C83B37"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M30.0001 36.8488C29.1625 36.8488 28.4834 36.1698 28.4834 35.3321V35.3321C28.4834 34.4945 29.1625 33.8154 30.0001 33.8154V33.8154C30.8378 33.8154 31.5168 34.4945 31.5168 35.3321V35.3321C31.5168 36.1698 30.8378 36.8488 30.0001 36.8488V36.8488Z" fill="#C83B37"/>
          </svg>
        </slot>
      </v-card-title>
      <v-card-subtitle>{{ title }}</v-card-subtitle>
      <v-card-text v-if="text !== ''">{{ text }}</v-card-text>
      <v-card-actions>
        <v-btn
            color="primary"
            elevation="0"
            large
            block
            @click="confirm()"
        >ОК</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {

  name: 'SuccessDialog',

  props: {
    value: {
      type: Boolean,
      default: false
    },
    callback: {
      type: Function
    }
  },

  data() {
    return {
      visible: this.value,
      title: null,
      text: null
    }
  },

  watch: {
    visible(value) {
      this.$emit('input', value);
    }
  },

  methods: {
    show(title, text) {
      this.title = title;
      this.text = text;
      this.visible = true;
    },

    hide() {
      this.visible = false;
    },

    confirm() {
      this.hide();
      this.$emit('confirm');

      /**
       * fixme: legacy
       */
      if (typeof this.callback === "function") {
        this.callback();
      }
      if (this.redirectPath) {
        this.$router.push({ path: this.redirectPath });
      }
    }
  }
}
</script>

<style scoped>
/* remove dialog shadow */
::v-deep .v-dialog {
  box-shadow: none;
}
.v-sheet.v-card {
  box-shadow: none;
}

/* dialog style */
.v-dialog > .v-card {
  padding: 20px;
  border-radius: 16px;
}
.v-dialog > .v-card > .v-card__title {
  justify-content: center;

  margin-bottom: 20px;
}
.v-dialog > .v-card > .v-card__subtitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;

  color: #1A1F24;

  word-break: break-word;

  text-align: center;
}
.v-dialog > .v-card > .v-card__text {
  padding: 10px 20px 10px 10px;

  text-align: center;
}
</style>