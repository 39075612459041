<template>
  <v-menu
    offset-y
    min-width="320"
    max-width="320"
    :close-on-content-click="false"
    v-model="isOpened"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
          class="d-inline-block"
          style="position: relative"
          v-bind="attrs"
          v-on="on"
      >
        <v-btn class="ml-3" icon small>
          <v-icon>{{ icon }}</v-icon>
        </v-btn>
        <div class="notification-count" v-if="loadingCount || unreadNotificationsCount > 0">
          <span v-if="loadingCount"><v-progress-circular indeterminate size="8" width="1"></v-progress-circular></span>
          <span v-else>{{unreadNotificationsCount}}</span>
        </div>
      </div>
    </template>
    <v-list v-if="loading" style="text-align: center">
      <v-progress-circular
          class="mt-3 mb-2"
          color="primary"
          indeterminate
          size="20"
          width="3"
      ></v-progress-circular>
      <v-progress-linear
          :active="loading"
          indeterminate
          absolute
          top
          color="primary"
      ></v-progress-linear>
    </v-list>
    <v-list v-if="!loading && error" style="font-size: 14px; color: #999999; text-align: center">{{ error }}</v-list>
    <v-list v-if="!loading && !error && isEmpty" style="font-size: 14px; color: #999999; text-align: center;">Нет уведомлений</v-list>
    <v-list v-if="!loading && !error && !isEmpty">
      <v-list-item
        class="mb-2"
        v-for="notification in notifications"
        :key="notification.id"
        style="min-height: unset"
      >
        <v-container fluid class="pa-0">
          <div class="d-flex">
            <span
              :class="[notification.unread ? 'unread' : 'read']"
              class="round"
            />
            <p
              class="ml-2 mb-0 black--text text-subtitle-2"
              :class="[notification.unread ? null : 'font-weight-regular']"
            >
              {{ truncateString(notification.text, 60, '...') }}
            </p>
          </div>
          <p
            class="text-subtitle-2 secondary--text font-weight-regular ml-4 mb-0"
          >
            {{ formatDateStandart(notification.date, true, true, true) }}
          </p>
        </v-container>
      </v-list-item>
      <v-divider class="mb-2" />
      <v-list-item style="min-height: unset">
        <NotificationsModal ref="notificationsModal"/>
        <p
          class="ml-2 mb-0 text-subtitle-2 secondary--text font-weight-regular"
          style="cursor: pointer"
          @click="isOpened = false; $refs.notificationsModal.open();"
        >
          Посмотреть все уведомления
        </p>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import NotificationsModal from "./NotificationsModal.vue";

import { truncateString, formatDateStandart } from "@/utils";

import { getUnreadNotificationCount, listNotifications, markNotificationsAsRead } from "@/services/api";

const NOTIFICATION_UPDATE_INTERVAL = 30000;

export default {

  components: {
    NotificationsModal
  },

  props: {
    icon: {
      type: String,
      default: 'mdi-bell-circle'
    }
  },

  data() {
    return {
      isOpened: false,

      notifications: [],

      loading: false,
      loadingCount: false,
      unreadNotificationsCount: 0,

      error: "",

      checkNotificationsTimer: null,
    };
  },

  computed: {
    isEmpty() {
      return this.notifications && this.notifications.length <= 0;
    }
  },

  created() {
    this.getUnreadNotificationCount();
  },

  beforeCreate() {
    // check notifications count every X seconds
    this.checkNotificationsTimer = window.setInterval(() => {
      this.getUnreadNotificationCount(true);
    }, NOTIFICATION_UPDATE_INTERVAL);
  },

  beforeDestroy() {
    window.clearInterval(this.checkNotificationsTimer)
  },

  watch: {
    async isOpened(isOpened) {
      if (isOpened) {
        // list notifications
        await this.getNotifications();
        // mark notifications as soon as notifications list is opened
        await this.updateUnreadNotifications();
      }
    },
  },

  methods: {

    async getNotifications() {

      this.loading = true;

      try {
        const result = await listNotifications(1, 3);
        this.notifications = result.items;

      } catch(error) {
        this.$handleServerError(error);
        this.error = "Не удалось получить уведомления";

      } finally {
        this.loading = false;
      }
    },

    /**
     * @param {Boolean} quiet Показывать анимацию загрузки
     */
    async getUnreadNotificationCount(quiet = false) {
      try {
        if (!quiet) { this.loadingCount = true; }
        this.unreadNotificationsCount = await getUnreadNotificationCount();
        if (!quiet) { this.loadingCount = false; }
      } catch(err) {
        console.log(err);
      }
    },

    async updateUnreadNotifications() {
      const unread = this.notifications.filter((n) => n.unread);
      if (unread && unread.length > 0) {

        await markNotificationsAsRead(unread.map((n) => n.id));
        unread.forEach((n) => n.unread = false);

        await this.getUnreadNotificationCount(true);
      }
    },

    truncateString,
    formatDateStandart,
  }
};
</script>

<style src="./notifications.scss" lang="scss" scoped />
