import { render, staticRenderFns } from "./ForgotPasswordStep.vue?vue&type=template&id=d436d986&scoped=true&"
import script from "./ForgotPasswordStep.vue?vue&type=script&lang=js&"
export * from "./ForgotPasswordStep.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d436d986",
  null
  
)

export default component.exports