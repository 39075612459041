<template>
  <v-dialog
      v-model="visible"
      persistent
      max-width="450"
      overlay-color="black"
      overlay-opacity="0.64"
  >
    <v-card>
      <v-card-title>
        <slot>
          <v-icon
              size="100px"
              color="primary"
              style="opacity: 0.5"
          >
            mdi-check-circle
          </v-icon>
        </slot>
      </v-card-title>
      <v-card-subtitle v-html="title"></v-card-subtitle>
      <v-card-text v-if="text !== ''" v-html="text"></v-card-text>
      <v-card-actions>
        <v-btn
            color="primary"
            elevation="0"
            large
            block
            @click="confirm()"
        >
          {{ okText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {

  name: 'SuccessDialog',

  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Успешно'
    },
    text: {
      type: String,
      default: ''
    },
    okText: {
      type: String,
      default: 'OK'
    },
    callback: {
      type: Function
    }
  },

  data() {
    return {
      visible: this.value
    }
  },

  watch: {
    visible(value) {
      this.$emit('input', value);
    }
  },

  methods: {
    show() {
      this.visible = true;
    },

    hide() {
      this.visible = false;
    },

    confirm() {
      this.hide();
      this.$emit('confirm');

      /**
       * fixme: legacy
       */
      if (typeof this.callback === "function") {
        this.callback();
      }
      if (this.redirectPath) {
        this.$router.push({ path: this.redirectPath });
      }
    }
  }
}
</script>

<style scoped>
/* remove dialog shadow */
::v-deep .v-dialog {
  box-shadow: none;
}
.v-sheet.v-card {
  box-shadow: none;
}

/* dialog style */
.v-dialog > .v-card {
  padding: 20px;
  border-radius: 16px;
}
.v-dialog > .v-card > .v-card__title {
  justify-content: center;

  margin-bottom: 20px;
}
.v-dialog > .v-card > .v-card__subtitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;

  color: #1A1F24;

  word-break: break-word;

  text-align: center;
}
.v-dialog > .v-card > .v-card__text {
  padding: 10px 20px 10px 10px;

  text-align: center;
}
</style>