import Axios from "axios";
import BackendError from "@/models/BackendError";
import BackendValidationError from "@/models/BackendValidationError";
import { getCookie } from "@/utils";
import store from "@/store";

export const axios = Axios.create({
  baseURL: process.env.VUE_APP_DATA_SERVICE_URL,
});

axios.interceptors.request.use(
  function (config) {
    const auth = getCookie("auth");
    if (auth) {
      config.headers.common["Authorization"] = `Bearer ${auth}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      store.dispatch("logout").then(() => window.location.reload());
    }
    return Promise.reject(error);
  }
);

function getBackendError(errorData) {
  if (!errorData) {
    return new BackendError();
  }
  switch (errorData.code) {
    case -2:
      return new BackendValidationError(errorData.details.fields);
    default:
      return new BackendError(
        errorData.message,
        errorData.details,
        errorData.status,
        errorData.code
      );
  }
}

export function callAPI(method, url, data = {}) {
  return new Promise((resolve, reject) => {
    return axios({ method, url, data })
      .then((res) => {
        resolve(res.data.result);
      })
      .catch((axiosError) => {
        if (axiosError.code === "ERR_NETWORK") {
          reject(getBackendError({ message: "Нет соединения с сервером" }));
        }
        if (axiosError.response.data) {
          reject(
            getBackendError({
              ...axiosError.response.data.error,
              ...axiosError.response,
            })
          );
        } else {
          reject(axiosError);
        }
      });
  });
}

export function uploadFile(file, uploadCallback) {
  const formData = new FormData();
  formData.append("file", file);
  return axios
    .post("/api/user/uploadFile", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: uploadCallback,
    })
    .then((res) => res.data.result);
}

export function register(payload) {
  return callAPI("POST", "/api/auth/register", payload);
}

export function getAuthSessions(payload) {
  return callAPI("POST", "/api/user/getAuthSessions", payload);
}

export function getEventLog(payload) {
  return callAPI("POST", "/api/user/getJournalEvents", payload);
}

export function requestEmailVerification() {
  return callAPI("POST", "/api/user/requestEmailVerification", {});
}

export function resendCode(payload) {
  return callAPI("POST", "/api/otp/resendCode", payload);
}

export function setPassword(new_password, old_password) {
  return callAPI("POST", "/api/user/setPassword", {
    new_password: new_password,
    old_password: old_password,
  });
}

export function getUser() {
  return callAPI("GET", "/api/getProfile");
}

export function login(login, password) {
  return callAPI("POST", "/api/auth/login", {
    phone: login,
    password: password,
  });
}

export function logout() {
  return callAPI("POST", "/api/auth/logout");
}

export function listOrganizations() {
  return callAPI("POST", "/api/user/listOrganizations");
}

export function getOrganization(inn) {
  return callAPI("POST", "/api/user/getOrganization", { inn });
}

export function requestPasswordReset(payload) {
  return callAPI("POST", "/api/auth/requestPasswordReset", payload);
}

export function resetPassword(payload) {
  return callAPI("POST", "/api/auth/resetPassword", payload);
}

export function suggestOrganization(inn) {
  return callAPI("POST", "/api/user/suggestOrganization", { inn });
}

export function createOrganization(organizationData) {
  return callAPI("POST", "/api/user/createOrganization", organizationData);
}

export function saveOrganization(organizationData) {
  return callAPI("POST", "/api/user/saveOrganization", organizationData);
}

export function suggestAddress(query) {
  return callAPI("POST", "/api/user/suggestAddress", { query });
}

export function editOrganization(organizationData) {
  return callAPI("POST", "/api/user/editOrganization", organizationData);
}

export function deleteOrganization(inn) {
  return callAPI("POST", "/api/user/deleteOrganization", { inn });
}

export function editEmployee(payload) {
  return callAPI("POST", "/api/user/editOrganizationEmployee", payload);
}

export function addEmployee(payload) {
  return callAPI("POST", "/api/user/addOrganizationEmployee", payload);
}

export function listEmployees(inn) {
  return callAPI("POST", "/api/user/listOrganizationEmployees", {
    organization_inn: inn,
  });
}

export function getEmployee(inn, email) {
  return callAPI("POST", "/api/user/getOrganizationEmployee", {
    organization_inn: inn,
    email: email,
  });
}

export function deleteEmployee(inn, email) {
  return callAPI("POST", "/api/user/deleteOrganizationEmployee", {
    organization_inn: inn,
    email: email,
  });
}

export function requestAccreditation(payload) {
  return callAPI("POST", "/api/user/requestAccreditation", payload);
}

export function listAccreditationRequests() {
  return callAPI("GET", "/api/user/listAccreditationRequests");
}

export function listDraftAccreditationRequests() {
  return callAPI("GET", "/api/user/listDraftAccreditationRequests");
}

export function saveDraftAccreditationRequest(payload) {
  return callAPI("POST", "/api/user/saveDraftAccreditationRequest", payload);
}

export function editAccreditationRequest(payload) {
  return callAPI("POST", "/api/user/editAccreditationRequest", payload);
}

export function deleteAccreditationRequest(inn) {
  return callAPI("POST", "/api/user/deleteAccreditationRequest", {
    organization_inn: inn,
  });
}

export function getAccreditationRequest(inn) {
  return callAPI("POST", "/api/user/getAccreditationRequest", {
    organization_inn: inn,
  });
}

export function checkPhoneUnique(phone) {
  return callAPI("POST", "/api/auth/checkPhoneUnique", { phone });
}

export function checkEmailUnique(email) {
  return callAPI("POST", "/api/auth/checkEmailUnique", { email });
}

export function requestSMSCode(phone) {
  return callAPI("POST", "/api/otp/requestSMSCode", { phone });
}

export function verifyOTPCode(code, token) {
  return callAPI("POST", "/api/otp/verifyCode", { code, token });
}

export function editProfile(payload) {
  return callAPI("POST", "/api/user/editProfile", payload);
}

export function verifyEmail(payload) {
  return callAPI("POST", "/api/user/verifyEmail", payload);
}

export function listCategories(tag) {
  return callAPI("POST", "/api/user/listCategories", { tag: tag });
}

export function getCitiesList(regionCode) {
  return callAPI("POST", "/api/user/getCitiesList", {
    region_code: regionCode,
  });
}

export function suggestCities(search) {
  return callAPI("POST", "/api/user/suggestCities", {
    search,
  });
}

export function getRegionList() {
  return callAPI("POST", "/api/user/getRegionList");
}

export function getUnitsList() {
  return callAPI("GET", "/api/user/getUnits");
}

export function listProducts(payload) {
  return callAPI("POST", "/api/user/listProducts", payload);
}

export function createProduct(payload) {
  return callAPI("POST", "/api/user/createProduct", payload);
}

export function getProduct(payload) {
  return callAPI("POST", "/api/user/getProduct", payload);
}

export function saveProcurement(payload) {
  return callAPI("POST", "/api/user/saveProcurement", payload);
}

export function getOrganizationDeliveryAddresses(inn) {
  return callAPI("POST", "/api/user/getOrganizationDeliveryAddresses", {
    organization_inn: inn,
  });
}

export function listProcedures({
  listType,
  page,
  limit,
  status,
  auctionStartDate,
  auctionEndDate,
  categories,
  search,
  city,
  cities,
  tags,
}) {
  return callAPI("POST", "/api/user/getProcedureList", {
    list_type: listType,
    page: page,
    limit: limit,
    status: status,
    search: search,
    auction_opens_at: auctionStartDate,
    auction_closes_at: auctionEndDate,
    categories: categories,
    city: city,
    cities: cities,
    tags,
  });
}

export function getUnansweredProcedureQuestionsCount() {
  return callAPI("POST", "/api/user/getUnansweredProcedureQuestionsCount");
}

export function listQuestions(payload) {
  return callAPI("POST", "/api/user/listProcedureQuestions", payload);
}

export function listProcedureOffers(procedureId, organizationInn) {
  return callAPI("POST", "/api/user/listProcedureOffers", {
    procedure_id: procedureId,
    organization_inn: organizationInn,
  });
}

export function listProcedureOrders(procedureId, organizationInn) {
  return callAPI("POST", "/api/user/listProcedureOrders", {
    procedure_id: procedureId,
    organization_inn: organizationInn,
  });
}

export function getProcedureComment(payload) {
  return callAPI("POST", "/api/user/getProcedureComment", payload);
}

export function listProcedureComments(payload) {
  return callAPI("POST", "/api/user/listProcedureComments", payload);
}

export function getProcedure(id) {
  return callAPI("POST", "/api/user/getProcedure", { id: id });
}

export function getProcedureQuestion(procedureId, questionNumber) {
  return callAPI("POST", "/api/user/getProcedureQuestion", {
    procedure_id: procedureId,
    question_number: questionNumber,
  });
}

export function deleteProcedure(id) {
  return callAPI("POST", "/api/user/deleteProcedure", { id: id });
}

export function cancelProcedure(id) {
  return callAPI("POST", "/api/user/cancelProcedure", { id: id });
}

export function createProcedureQuestion(payload) {
  return callAPI("POST", "/api/user/createProcedureQuestion", payload);
}

export function applyProcedureParticipation(procedureId, organizationInn) {
  return callAPI("POST", "/api/user/applyProcedureParticipation", {
    procedure_id: procedureId,
    organization_inn: organizationInn,
  });
}

export function submitProcedureOffers(payload) {
  return callAPI("POST", "/api/user/submitProcedureOffers", payload);
}

export function getProcedureProtocol(procedureId, organizationInn) {
  return callAPI("POST", "/api/user/getProcedureProtocol", {
    procedure_id: procedureId,
    organization_inn: organizationInn,
  });
}

export function deleteProcurementPosition(payload) {
  return callAPI("POST", "/api/user/deleteProcurementPosition", payload);
}

export function placeProcedureOrders(payload) {
  return callAPI("POST", "/api/user/placeProcedureOrders", payload);
}

export function summarizeProcedure(procedure_id) {
  return callAPI("POST", "/api/user/summarizeProcedure", {
    procedure_id,
  });
}

export function getInvitedEmployee(invite) {
  return callAPI("POST", "/api/auth/getInvitedEmployee", { invite });
}

export function listCatalogDrafts() {
  return callAPI("POST", "/api/user/listProcurementDrafts");
}

export function setCatalogDraftProduct(procedureId, productId, quantity) {
  return callAPI("POST", "/api/user/setProcurementProductQuantity", {
    procedure_id: procedureId,
    product_id: productId,
    quantity: quantity,
  });
}

export function answerProcedureQuestion(
  procedureId,
  questionNumber,
  answerText,
  isPrivate
) {
  return callAPI("POST", "/api/user/answerProcedureQuestion", {
    procedure_id: procedureId,
    question_number: questionNumber,
    answer_text: answerText,
    is_private: isPrivate,
  });
}

export function getWallet(organization_inn) {
  return callAPI("POST", "/api/user/getWallet", {
    organization_inn,
  });
}

export function getWalletHistory(
  organization_inn,
  page = 1,
  limit = 10,
  filters
) {
  return callAPI("POST", "/api/user/getWalletHistory", {
    organization_inn,
    page,
    limit,
    ...filters,
  });
}

export function getTotalBonuses() {
  return callAPI("POST", "/api/user/getTotalBonuses");
}

export function getPlanList() {
  return callAPI("POST", "/api/user/getPlanList");
}

export function getActivePlan(organization_inn) {
  return callAPI("POST", "/api/user/getActivePlan", {
    organization_inn,
  });
}

export function getReferalLink(payload) {
  return callAPI("POST", "/api/user/getReferralLink", payload);
}

export function listProtocolSignatures(payload) {
  return callAPI("POST", "/api/user/listProtocolSignatures", payload);
}

export function signProtocol(payload) {
  return callAPI("POST", "/api/user/signProtocol", payload);
}

export function getUnreadNotificationCount() {
  return callAPI("POST", "/api/user/getUnreadNotificationCount");
}

export function listNotifications(page = 1, limit = 3) {
  return callAPI("POST", "/api/user/listNotifications", {
    page,
    limit,
  });
}

export function markNotificationsAsRead(notification_ids) {
  return callAPI("POST", "/api/user/markNotificationsAsRead", {
    notification_ids,
  });
}

export function deactivateActivePlan(organization_inn) {
  return callAPI("POST", "/api/user/deactivateActivePlan", {
    organization_inn,
  });
}

export function activatePlan(params) {
  return callAPI("POST", "/api/user/activatePlan", params);
}

export function getWalletRequests(params) {
  return callAPI("POST", "/api/user/getWalletRequests", {
    ...params,
    order_by: "created_at",
  });
}

export function depositFunds(organization_inn, amount) {
  return callAPI("POST", "/api/user/depositFunds", {
    organization_inn,
    amount,
  });
}

export function requestWithdrawal(organization_inn, amount) {
  return callAPI("POST", "/api/user/requestWithdrawal", {
    organization_inn,
    amount,
  });
}

export function cancelWithdrawal(request_id) {
  return callAPI("POST", "/api/user/cancelWithdrawal", {
    request_id,
  });
}

export function listDocuments(params) {
  return callAPI("POST", "/api/user/listDocuments", params);
}

export function getAnalyticsReport(dateFrom, dateTo) {
  return callAPI("POST", "/api/user/getAnalyticsReport", {
    date_from: dateFrom,
    date_to: dateTo,
  });
}

export function getAnalyticsStats(dateFrom, dateTo) {
  return callAPI("POST", "/api/user/getAnalyticsStats", {
    date_from: dateFrom,
    date_to: dateTo,
  });
}

export function addProductToFavourites(productId) {
  return callAPI("POST", "/api/user/addProductToFavourites", {
    product_id: productId,
  });
}

export function removeProductFromFavourites(productId) {
  return callAPI("POST", "/api/user/removeProductFromFavourites", {
    product_id: productId,
  });
}

export function saveProcedureOfferDrafts(params) {
  return callAPI("POST", "/api/user/saveProcedureOfferDrafts", params);
}

export function getProcedureOfferDrafts(procedureId, organizationInn) {
  return callAPI("POST", "/api/user/getProcedureOfferDrafts", {
    procedure_id: procedureId,
    organization_inn: organizationInn,
  });
}

export function getProcedurePositionsReport(payload) {
  return callAPI("POST", "/api/user/getProcedurePositionsReport", payload);
}

export function getUnorderedPositionsReport(payload) {
  return callAPI("POST", "/api/user/getUnorderedPositionsReport", payload);
}

export function getTelegramQRCode() {
  return callAPI("POST", "/api/user/getTelegramQRCode");
}

export function unlinkTelegram() {
  return callAPI("POST", "/api/user/unlinkTelegram");
}

export function getOnboardingProgress() {
  return callAPI("POST", "/api/user/getOnboardingProgress");
}

export function getCurrentCommission(organization_inn) {
  return callAPI("POST", "/api/user/getCurrentCommission", {
    organization_inn,
  });
}

export function listOrganizationGroups() {
  return callAPI("POST", "/api/user/listOrganizationGroups");
}

export function saveOrganizationGroup(groupData) {
  return callAPI("POST", "/api/user/saveOrganizationGroup", groupData);
}

export function getBankSuggestion(bic) {
  return callAPI("POST", "/api/user/getBankSuggestion", {
    bic: bic,
  });
}

export function deleteOrganizationGroup(group_id) {
  return callAPI("POST", "/api/user/deleteOrganizationGroup", {
    group_id,
  });
}

export function requestOrganizationGroupChange(organization_inn, group_id) {
  return callAPI("POST", "/api/user/requestOrganizationGroupChange", {
    organization_inn,
    group_id,
  });
}

export function getOrganizationGroup(id) {
  return callAPI("POST", "/api/user/getOrganizationGroup", {
    id,
  });
}

export function upload(formData) {
  return callAPI("POST", "/api/user/upload", formData);
}

export function listTags(params) {
  return callAPI("POST", "/api/user/listTags", params);
}

export function getOrganizationGroupProfile(id, inn) {
  const params = {};
  if (id) params.id = id;
  if (inn) params.inn = inn;

  return callAPI("POST", "/api/user/getOrganizationGroupProfile", params);
}

export function addTag(name) {
  return callAPI("POST", "/api/user/addTag", {
    name,
  });
}

export function findAddress(search) {
  return callAPI("POST", "/api/user/findAddress", {
    search,
  });
}

export function getPlansHistory(organization_inn, page = 1, limit = 10) {
  return callAPI("POST", "/api/user/getPlansHistory", {
    organization_inn,
    page,
    limit,
  });
}

export function addProcurementAttachment(params) {
  return callAPI("POST", "/api/user/addProcurementAttachment", params);
}

export function getProcurementAttachments(procedure_id, organization_inn) {
  return callAPI("POST", "/api/user/getProcurementAttachments", {
    procedure_id,
    organization_inn,
  });
}

export function deleteProcurementAttachment(attachment_id) {
  return callAPI("POST", "/api/user/deleteProcurementAttachment", {
    attachment_id,
  });
}

export function getUnits() {
  return callAPI("GET", "/api/user/getUnits");
}

export function getProcedureCriteria(procedure_id, organization_inn) {
  return callAPI("POST", "/api/user/getProcedureCriteria", {
    procedure_id,
    organization_inn,
  });
}

export function getProcurementApplications(procedure_id, organization_inn) {
  return callAPI("POST", "/api/user/getProcurementApplications", {
    procedure_id,
    organization_inn,
  });
}

export default axios;
