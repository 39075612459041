<template>
  <div>
    <navigation-drawer-header @close="$emit('close')"/>
    <h1 class="text-center" style="margin-top: 90px; margin-bottom: 90px;">Восстановление пароля</h1>
    <div class="text-center" style="color: #494949; font-size: 16px; margin-bottom: 30px;">Укажите номер телефона, который вы использовали<br>при регистрации.</div>
    <ValidationObserver v-slot="{ invalid }">
      <v-form @submit.prevent="resetPassword()">
        <ValidationProvider
            rules="required"
            v-slot="{ errors }"
            name="номер телефона"
        >
          <phone-input
              color="primary"
              outlined
              rounded
              dense
              label="Номер телефона"
              :error-messages="errors"
              :disabled="loading"
              v-model="phone"
          >
            <template v-slot:append>
              <v-tooltip top min-width="220px" max-width="220px">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                  > mdi-information </v-icon>
                </template>
                <span style="font-size: 14px; line-height: 18px;">На этот номер телефона поступит код для восстановления пароля</span>
              </v-tooltip>
            </template>
          </phone-input>
        </ValidationProvider>

        <v-btn
            color="primary"
            block
            large
            height="48px"
            :disabled="invalid"
            :loading="loading"
            type="submit"
            elevation="0"
        >Восстановить пароль</v-btn>
      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PhoneInput from "@/components/PhoneInput";
import { requestPasswordReset } from "@/services/api";
import NavigationDrawerHeader from "@/components/Auth/ForgotPassword/NavigationDrawerHeader";

export default {

  name: "ForgotPasswordStep",

  components: {
    NavigationDrawerHeader,
    PhoneInput,
    ValidationProvider,
    ValidationObserver
  },

  data: () => ({
    phone: "",
    loading: false
  }),

  inject: [ 'showError' ],

  methods: {
    async resetPassword() {
      try {
        this.loading = true;
        const result = await requestPasswordReset({ phone: this.phone });
        this.$store.state.smsSentAt = Date.now();
        this.$emit('done', { phone: this.phone, token: result.token });
      } catch (error) {
        this.$emit('permanent:show', true);
        this.showError(error.message).then(() => {
          this.$emit('permanent:show', false);
        });
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
