<template>
  <div style="display: flex; flex-direction: column; height: 100%;">
    <navigation-drawer-header @close="$emit('close')" style="margin-bottom: 90px;"/>
    <h1 class="text-center" style="font-size: 32px; color: #000000; margin: 0 0 64px;">Вход</h1>
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-form @submit.prevent="login()" style="margin-bottom: 24px;">
        <ValidationProvider
          rules="required|phone"
          v-slot="{ errors }"
          name="номер телефона"
          mode="lazy"
          vid="phone"
        >
        <phone-input
            color="primary"
            outlined
            rounded
            dense
            name="phone"
            placeholder="Номер телефона"
            label="Номер телефона"
            :disabled="loading"
            :error-messages="errors"
            v-model="phone"
        ></phone-input>
        </ValidationProvider>

        <ValidationProvider
            :rules="{ required: true, min: 6 }"
            v-slot="{ errors }"
            name="пароль"
            vid="password"
        >
          <v-text-field
              color="primary"
              outlined
              rounded
              dense
              name="password"
              placeholder="Пароль (не менее 6 символов)*"
              label="Пароль"
              :append-icon="passwordVisible ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="passwordVisible = !passwordVisible"
              :type="passwordVisible ? 'text' : 'password'"
              :hide-details="false"
              v-model="password"
              :disabled="loading"
              :error-messages="errors"
          ></v-text-field>
        </ValidationProvider>
        <div class="text-right mb-2"><span class="link" style="cursor: pointer; font-size: 14px;" @click="$emit('forgot')">Забыли пароль?</span></div>
        <div class="text-right"><a class="link" style="font-size: 14px;" @click="$emit('register')">Зарегистрироваться</a></div>
        <p v-if="message" style="color: red; font-size: 14px;" class="my-0">{{ message }}</p>

        <div style="margin-bottom: 64px;"></div>

        <v-btn
            color="primary"
            elevation="0"
            block
            large
            height="48px"
            :disabled="invalid || loading"
            type="submit"
            :loading="loading"
        >Войти</v-btn>
      </v-form>
    </ValidationObserver>
    <v-spacer/>
    <v-divider style="margin-bottom: 30px;"/>
    <!-- <div style="margin-bottom: 10px;">Не зарегистрированы? <a class="link" style="font-size: 14px;" @click="$emit('register')">Зарегистрироваться</a></div> -->
    <div>Возникли вопросы? <a class="link" style="font-size: 14px;" @click.prevent="openAmoChat">Написать в техническую поддержку</a></div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { login } from "@/services/api";
import PhoneInput from "@/components/PhoneInput";
import { isMobileDevice, openAmoChat, setCookie } from "@/utils";
import NavigationDrawerHeader from "@/components/Auth/ForgotPassword/NavigationDrawerHeader";

export default {

  name: "LoginForm",

  components: {
    NavigationDrawerHeader,
    PhoneInput,
    ValidationProvider,
    ValidationObserver
  },

  data: () => ({
    passwordVisible: false,
    phone: "",
    password: "",
    loading: false,
    message: ""
  }),

  methods: {
    openAmoChat,

    async login() {

      this.loading = true;

      await login(this.phone, this.password)
          .then((token) => {
            setCookie('auth', token);
            if (isMobileDevice()) {
              window.location.href = this.$route.query.redirect ?? '/organizations';
            } else {
              window.location.href = this.$route.query.redirect ?? '/procedures';
            }
          })
          .catch((error) => {
            this.$handleServerError(error, this.$refs.observer);
            this.loading = false;
          });
    }
  }
}
</script>

<style scoped>
.link:hover {
  opacity: 0.8;
}
</style>
