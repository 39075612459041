<template>
  <div id="app">
    <LayoutBroker :layouts="$options.LAYOUTS" :current="$route.meta.layout" />
    <AlertModal ref="alert"/>
  </div>
</template>

<script>
import LayoutBroker from '@/components/LayoutBroker';

import EmptyLayout from '@/layouts/EmptyLayout.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import LKLayout from '@/layouts/LKLayout.vue';
import ProductLayout from "@/layouts/ProductLayout.vue";
import ConfirmLayout from "@/layouts/ConfirmLayout.vue";

import AmoCRMButton from 'raw-loader!@Injects/amocrm-button.txt';
import AlertModal from "@/components/AlertModal.vue";

import { eventBus } from "@/events";

const LAYOUTS = {
  EmptyLayout,
  DefaultLayout,
  LKLayout,
  ConfirmLayout,
  ProductLayout
}

export default {

  name: 'App',

  components: { AlertModal, LayoutBroker },

  LAYOUTS,

  beforeCreate() {
    this.$store.dispatch('init')
        .then(() => {
          // init amocrm
          this.$nextTick(() => { this.initAmoCRM(this.$store.state.auth?.user?.id) });
        });
  },

  mounted() {
    eventBus.$on('error', (params) => this.$refs.alert.show(params));
  },

  methods: {
    initAmoCRM(userId) {
      /* https://www.amocrm.ru/developers/content/web_sdk/crm_plugin */
      window.amoSocialButtonConfig = {
        color: '#1B7DC9',
        onlinechat: {
          theme: {
            background: '#f6f6f8',
            header: {
              background: '#1B7DC9',
              color: 'white'
            }
          }
        }
      };

      // provide amoCRM chat user ID
      if (userId) {
        window.amoSocialButtonConfig.onlinechat.user_id = String(userId);
      }

      // inject amoCRM button
      const div = document.createElement('div');
      div.innerHTML = AmoCRMButton;
      window.eval(div.textContent);
    }
  }
}
</script>

<style scoped lang="scss">

</style>

