<template>
  <div>
    <navigation-drawer-header @close="$emit('close')" style="margin-bottom: 90px"/>
    <h1 style="font-size: 32px; font-weight: 500; margin-bottom: 130px;">Восстановление пароля</h1>
    <div style="margin-bottom: 90px;">
      <h2 style="font-size: 30px; font-weight: 500; margin-bottom: 30px; color: #474747;">Пароль успешно изменен</h2>
      <img src="@/assets/done.png" alt="done" class="mx-auto">
    </div>
    <div>
      <v-btn
          color="primary"
          elevation="0"
          block
          large
          height="48px"
          @click="$emit('done')"
      >Войти</v-btn>
    </div>
  </div>
</template>

<script>
import NavigationDrawerHeader from "@/components/Auth/ForgotPassword/NavigationDrawerHeader";
export default {
  name: "SuccessStep",
  components: { NavigationDrawerHeader },
}
</script>

<style scoped>

</style>