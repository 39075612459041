interface Breadcrumb {
  name: string;
  routeName?: string;
  disabled?: boolean;
  prevRouteParam?: string;
  currentRouteParam?: string;
  routeParams?: object;
}

const PROCEDURES: Breadcrumb = {
  name: "Закупки",
};

const PROCEDURES_CREATE: Breadcrumb[] = [
  {
    name: "Закупки",
    routeName: "procedures",
  },
  {
    name: "Создание закупки",
  },
];

const PROCEDURES_EDIT: Breadcrumb[] = [
  PROCEDURES_CREATE[0],
  {
    name: "Процедура закупки",
    routeName: "procedures.view",
    prevRouteParam: "id",
    currentRouteParam: "id",
  },
  {
    name: "Редактирование закупки",
  },
];

const PROCEDURES_OFFERS: Breadcrumb[] = [
  PROCEDURES_CREATE[0],
  {
    name: "Процедура закупки",
    routeName: "procedures.view",
    prevRouteParam: "id",
    currentRouteParam: "procedureId",
  },
  {
    name: "Выбор лучших предложений",
  },
];

const PROCEDURES_QUESTION: Breadcrumb[] = [
  PROCEDURES_OFFERS[0],
  PROCEDURES_OFFERS[1],
  {
    name: "Ответ на запрос",
  },
];

const PROCEDURES_VIEW: Breadcrumb[] = [
  PROCEDURES_CREATE[0],
  {
    name: "Процедура закупки",
  },
];

const FINANCE: Breadcrumb = {
  name: "Финансы",
};

const FINANCE_WALLET: Breadcrumb[] = [
  {
    ...FINANCE,
    routeName: "finance",
  },
  { name: "Кошелёк" },
];

const FINANCE_TRANSACTIONS: Breadcrumb[] = [
  {
    ...FINANCE,
    routeName: "finance",
  },
  { name: "История операций" },
];

const FINANCE_TARIFS_HISTORY: Breadcrumb[] = [
  {
    ...FINANCE,
    routeName: "finance",
  },
  { name: "История тарифов" },
];

const FINANCE_SERVICES: Breadcrumb[] = [
  {
    ...FINANCE,
    routeName: "finance",
  },
  { name: "Тарифы и услуги" },
];

const FINANCE_SERVICES_ACTIVATE: Breadcrumb[] = [
  {
    ...FINANCE,
    routeName: "finance",
  },
  {
    name: "Тарифы и услуги",
    routeName: "finance-services",
    prevRouteParam: "inn",
    currentRouteParam: "inn",
  },
  { name: "Активация тарифа" },
];

const FINANCE_TARIFS: Breadcrumb[] = [
  {
    ...FINANCE,
    routeName: "finance",
  },
  { name: "Мой Тариф" },
];

const FINANCE_BONUSES: Breadcrumb[] = [
  {
    ...FINANCE,
    routeName: "finance",
  },
  {
    name: "Бонусы",
  },
];

const ORGANIZATIONS: Breadcrumb = {
  name: "Мои организации",
};

const ORGANIZATIONS_GROUPS: Breadcrumb = {
  name: "Группы компаний",
};

const ORGANIZATIONS_GROUPS_EDIT: Breadcrumb[] = [
  {
    ...ORGANIZATIONS_GROUPS,
    routeName: "organizations_groups",
  },
  {
    name: "Данные группы компаний",
  },
];

const ORGANIZATIONS_EDIT: Breadcrumb[] = [
  {
    ...ORGANIZATIONS,
    routeName: "organizations",
  },
  {
    name: "Редактирование организации",
  },
];

const ORGANIZATIONS_CREATE: Breadcrumb[] = [
  {
    ...ORGANIZATIONS,
    routeName: "organizations",
  },
  {
    name: "Создание организации",
  },
];

const JOURNAL: Breadcrumb = {
  name: "Журнал",
};

const CATALOG: Breadcrumb = {
  name: "Каталог",
};

const CATALOG_PRODUCT: Breadcrumb[] = [
  {
    ...CATALOG,
    routeName: "catalog",
  },
  {
    name: "Товар",
  },
];

const ANALYTICS: Breadcrumb = {
  name: "Аналитика",
};

const DOCUMENTS: Breadcrumb = {
  name: "Документы",
};

const ACCREDITATION: Breadcrumb = {
  name: "Аккредитация",
};

const PROFILE: Breadcrumb = {
  name: "Личные данные",
};

const BREADCRUMBS = {
  PROCEDURES,
  PROCEDURES_CREATE,
  PROCEDURES_EDIT,
  PROCEDURES_OFFERS,
  PROCEDURES_QUESTION,
  PROCEDURES_VIEW,
  FINANCE,
  FINANCE_WALLET,
  FINANCE_TRANSACTIONS,
  FINANCE_TARIFS_HISTORY,
  FINANCE_SERVICES,
  FINANCE_SERVICES_ACTIVATE,
  FINANCE_TARIFS,
  FINANCE_BONUSES,
  ORGANIZATIONS,
  ORGANIZATIONS_GROUPS,
  ORGANIZATIONS_GROUPS_EDIT,
  ORGANIZATIONS_EDIT,
  ORGANIZATIONS_CREATE,
  JOURNAL,
  CATALOG,
  CATALOG_PRODUCT,
  ANALYTICS,
  DOCUMENTS,
  ACCREDITATION,
  PROFILE,
};

export { Breadcrumb, BREADCRUMBS };
