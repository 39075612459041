var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":_vm.width || _vm.maxWidth,"overlay-color":"black","overlay-opacity":"0.64","persistent":_vm.isCancelCbk || _vm.persistent},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',{style:({
      padding: _vm.dialogPadding + 'px',
    })},[_c('v-card-title',{staticClass:"px-4 px-md-10",style:({
        'background-color': _vm.headerBg ? _vm.headerBg : null,
      })},[_c('div',{staticStyle:{"width":"100%","display":"flex","justify-content":"space-between","align-items":"start","gap":"10px"}},[_vm._v(" "+_vm._s(_vm.title)+" "),_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.cancel()}}},[_c('CloseSimple')],1)])]),_c('v-card-text',{staticClass:"px-4 px-md-10"},[_vm._t("default",function(){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.text)}})]})],2),_c('v-card-actions',{staticClass:"px-4 px-md-10",style:({
        'background-color': _vm.footerBg ? _vm.footerBg : null,
      })},[_c('v-btn',{staticClass:"mr-5",staticStyle:{"flex":"1"},attrs:{"large":"","outlined":"","color":_vm.cancelColor},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" "+_vm._s(_vm.cancelText)+" ")]),_c('v-btn',{staticStyle:{"flex":"1"},attrs:{"large":"","disabled":_vm.disabled,"color":_vm.okColor,"elevation":"0","loading":_vm.loading || _vm.mutLoading},on:{"click":function($event){return _vm.confirm()}}},[_vm._v(" "+_vm._s(_vm.okText)+" "),(_vm.okIcon)?_c('v-icon',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.okIcon))]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }