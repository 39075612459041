<template>
  <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.962427 4.73543C4.72052 3.18307 7.2265 2.15967 8.48037 1.66521C12.0604 0.253426 12.8043 0.00818437 13.2892 8.63431e-05C13.3959 -0.00169473 13.6343 0.0233625 13.7887 0.142186C13.9192 0.242518 13.955 0.378053 13.9722 0.473179C13.9894 0.568305 14.0108 0.785005 13.9938 0.954328C13.7998 2.88695 12.9603 7.57691 12.5332 9.74148C12.3525 10.6574 11.9967 10.9645 11.6522 10.9945C10.9036 11.0599 10.3351 10.5255 9.61007 10.0749C8.47546 9.36971 7.83448 8.93076 6.73315 8.24267C5.46037 7.44746 6.28546 7.0104 7.01082 6.29612C7.20064 6.10919 10.4991 3.26471 10.5629 3.00668C10.5709 2.9744 10.5783 2.85411 10.503 2.79059C10.4276 2.72707 10.3163 2.74879 10.236 2.76606C10.1223 2.79055 8.3097 3.9264 4.79841 6.1736C4.28392 6.50854 3.81791 6.67174 3.40039 6.66319C2.9401 6.65376 2.05469 6.41644 1.39648 6.21359C0.589162 5.96478 -0.0524811 5.83324 0.00339163 5.41069C0.0324936 5.1906 0.352172 4.96551 0.962427 4.73543Z" fill="#8C8C8C"/>
  </svg>

</template>

<script>
export default {
  name: "TelegramIcon"
}
</script>

<style scoped>

</style>