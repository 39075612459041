import { getTotalBonuses, getWallet } from "@/services/api";
import router from "@/router";

const finance = {
  namespaced: true,
  state: () => ({
    organization: null,
    totalBonuses: null,
    wallet: null,
    plan: null,
  }),
  actions: {
    setPlan({ commit }, plan) {
      commit("SET_PLAN", plan);
    },
    setOrganization({ commit }, organization) {
      commit("SET_ORGANIZATION", organization);
    },
    setWallet({ commit }, wallet) {
      if (wallet) {
        commit("SET_WALLET", wallet);
      } else {
        return new Promise((resolve, reject) => {
          const inn = router.currentRoute.params?.inn;

          getWallet(inn)
            .then((response) => {
              resolve(response);
              commit("SET_WALLET", response);
            })
            .catch((error) => reject(error));
        });
      }
    },
    async setTotalBonuses({ commit }, totalBonusesSum) {
      if (totalBonusesSum) {
        commit("SET_TOTAL_BONUSES", totalBonusesSum);
      } else {
        const totalBonuses = await getTotalBonuses();
        commit("SET_TOTAL_BONUSES", totalBonuses);
      }
    },
    clearFinance({ commit }) {
      commit("SET_ORGANIZATION", null);
      commit("SET_TOTAL_BONUSES", null);
      commit("SET_WALLET", null);
    },
  },
  mutations: {
    SET_ORGANIZATION(state, organization) {
      state.organization = organization;
    },
    SET_TOTAL_BONUSES(state, totalBonusesSum) {
      state.totalBonuses = totalBonusesSum;
    },
    SET_WALLET(state, wallet) {
      state.wallet = wallet;
    },
    SET_PLAN(state, plan) {
      state.plan = plan;
    },
  },
  getters: {
    inn(state) {
      return state.organization?.inn;
    },
    totalBonuses(state) {
      return state.totalBonuses;
    },
    wallet(state) {
      return state.wallet;
    },
    organizationBonuses(state) {
      return state.wallet?.bonuses;
    },
    plan(state) {
      return state.plan;
    },
    selectedOrganization(state) {
      return state.organization;
    },
    isSelectedOrganizationPrivateOrLegal(state) {
      return (
        state.organization?.type === "private" ||
        state.organization?.type === "individual"
      );
    },
  },
};

export default finance;
