import { deleteCookie, getCookie } from "@/utils";
import { getUser } from "@/services/api";

import Vue from "vue";
import Vuex from "vuex";
import finance from "./finance";
import progress from "./progress";
import router from "@/router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    breadcrumbs: [],
    isInit: null,
    auth: {
      token: null,
      user: null,
      registration: {
        email: null,
        name: null,
        last_name: null,
        middle_name: null,
        occupation: null,
        phone: null,
        is_terms_of_service_accepted: false,
        is_advertising_agreement_accepted: false,
      },
    },
    otpVerificationToken: null,
  },
  getters: {
    isAuthenticated: (state) => !!state.auth.user,
    userRole: (state) => state.auth?.user?.role,
    isOnboardingDone: (state) => state.auth?.user?.is_onboarding_done,
    isSupplier: (state) => state.auth?.user?.role === "supplier",
    isCustomer: (state) => state.auth?.user?.role === "customer",
  },
  mutations: {
    SET_BREADCRUMBS(state, breadcrumbs) {
      state.breadcrumbs = breadcrumbs;
    },
    SET_TOKEN(state, token) {
      state.auth.token = token;
    },
    SET_VERIFICATION_TOKEN(state, token) {
      state.otpVerificationToken = token;
    },
    SET_ACCOUNT(state, payload) {
      Vue.set(state.auth, "user", payload);
    },
    SET_INIT(state, status) {
      state.isInit = status;
    },
    SET_REGISTRATION(state, payload) {
      state.auth.registration = payload;
    },
    SET_EMAIL(state, payload) {
      state.auth.user.email = payload;
    },
  },
  actions: {
    buildBreadcrumbs({ commit }, data = []) {
      const breadcrumbs = [];

      // always show a home page
      breadcrumbs.push({
        name: "Главная",
        disabled: false,
        routeName: "procedures",
      });

      const getCrumbWithParams = (data) => {
        if (Array.isArray(data)) {
          return data.map((crumb) => {
            if (crumb.currentRouteParam && crumb.prevRouteParam) {
              crumb.routeParams = {
                [crumb.prevRouteParam]:
                  router?.currentRoute?.params[crumb.currentRouteParam],
              };
            }
            return crumb;
          });
        } else {
          if (data.currentRouteParam && data.prevRouteParam) {
            data.routeParams = {
              [data.prevRouteParam]:
                router?.currentRoute?.params[data.currentRouteParam],
            };
          }
          return data;
        }
      };

      if (data) {
        if (Array.isArray(data)) {
          breadcrumbs.push(...getCrumbWithParams(data));
        } else {
          breadcrumbs.push(getCrumbWithParams(data));
        }
      }

      // // add breadcrumbs data from method arguments
      // if (Array.isArray(data.crumb)) {
      //   breadcrumbs.push(...data.crumb);
      // }
      // // add a single disabled breadcrumb element if "data" is string
      // if (typeof data === "string") {
      //   breadcrumbs.push({
      //     text: data.crumb,
      //     disabled: true,
      //   });
      // }

      // update breadcrumbs
      commit("SET_BREADCRUMBS", breadcrumbs);
    },

    // async resetPassword({ commit }, phone) {
    // },

    async init({ commit, dispatch, state }) {
      if (state.isInit) {
        return;
      }

      const token = getCookie("auth");
      if (token) {
        commit("SET_TOKEN", token);
        await dispatch("fetchAccount");
      }
      commit("SET_INIT", true);
    },

    async fetchAccount({ commit }) {
      await getUser()
        .then((data) => {
          commit("SET_ACCOUNT", data);
        })
        .catch((error) => {
          if (error.status === 401) {
            // session expired
            deleteCookie("auth");
          }
        });
    },

    async logout({ commit, dispatch }) {
      deleteCookie("auth");
      commit("SET_TOKEN", null);
      commit("SET_ACCOUNT", null);
      dispatch("finance/clearFinance", null, { root: true });
    },
  },
  modules: {
    finance,
    progress,
  },
});
