<template>
  <header :class="{ 'topbar-elevated': elevated }">
    <v-container class="py-0">
      <div class="topbar d-flex align-center flex-column flex-md-row justify-md-space-between">
        <div class="d-flex align-center" style="flex-basis: 100%">
          <a
              href="tel:+78002004132"
              class="mr-4 black--text text-decoration-none"
          >
            8 800 200 41 32
          </a>
          <a class="mx-4" href="https://vk.com/atis_trade" target="_blank">
            <VkIcon />
          </a>
          <a class="mx-4" href="https://t.me/atis_trade" target="_blank">
            <TelegramIcon />
          </a>
        </div>
        <div class="d-flex align-center justify-center mt-4 mt-md-0" style="flex-basis: 100%">
          <router-link to="/">
            <img alt="logo" src="@/assets/logo-colored.svg" />
          </router-link>
        </div>
        <div class="d-flex align-center justify-end" style="flex-basis: 100%">
          <v-progress-circular v-if="!$store.state.isInit" size="16" width="2" color="primary" indeterminate/>
          <v-fade-transition v-else mode="in-out">
            <div v-if="!$store.getters.isAuthenticated" class="d-flex align-center">
              <v-btn
                  elevation="0"
                  color="#FFFFFF"
                  class="topbar-login mx-2"
                  v-on:click="$emit('login')"
              >
                <v-icon left color="#1B7DC9"> mdi-exit-to-app </v-icon>
                Вход
              </v-btn>
              <svg
                  width="1"
                  height="18"
                  viewBox="0 0 1 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <line
                    x1="0.5"
                    y1="2.18556e-08"
                    x2="0.499999"
                    y2="18"
                    stroke="black"
                />
              </svg>
              <v-btn
                  elevation="0"
                  color="#FFFFFF"
                  class="topbar-registration ml-2"
                  v-on:click="$emit('registration')"
              >Регистрация</v-btn
              >
            </div>
            <div v-else class="mt-3 mt-md-0">

              <Notifications/>

              <v-menu offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small class="ml-3" v-bind="attrs" v-on="on">
                    <v-icon>mdi-account-circle</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item link to="/procedures">
                    <v-list-item-title>Личный кабинет</v-list-item-title>
                  </v-list-item>
                  <v-divider />
                  <v-list-item link @click="$store.dispatch('logout')">
                    <v-list-item-title>Выйти</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-fade-transition>
        </div>
      </div>
    </v-container>
  </header>
</template>

<script>
import VkIcon from "@/components/Icons/Vk";
import TelegramIcon from "@/components/Icons/Telegram";
import { openAmoChat } from "@/utils";
import Notifications from "./Shared/Notifications/Notifications.vue";

export default {
  name: "TopBar",
  props: {
    elevated: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VkIcon,
    TelegramIcon,
    Notifications,
  },
  methods: {
    openAmoChat() {
      openAmoChat();
    },
  },
};
</script>

<style scoped>
.topbar {
  margin: 18px auto;
  font-size: 14px;
}

.topbar-login,
.topbar-registration {
  color: #000;
  text-decoration: none;
  font-weight: normal;
}

.topbar-elevated {
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
}
</style>
