<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_769_17518)"> <path d="M4.95236 5.92844L11.0342 12.0103L4.90274 18.1418C4.60611 18.4384 4.60577 18.834 4.90274 19.131C5.19937 19.4276 5.59498 19.428 5.89195 19.131L12.0234 12.9995L18.1053 19.0814C18.4019 19.378 18.7975 19.3783 19.0945 19.0814C19.3911 18.7847 19.3915 18.3891 19.0945 18.0921L13.0123 12.0106L19.0945 5.92843C19.3911 5.63181 19.3915 5.23619 19.0945 4.93922C18.7979 4.64259 18.4023 4.64224 18.1053 4.93922L11.9738 11.0707L5.89196 4.98884C5.59533 4.69221 5.19972 4.69187 4.90275 4.98884C4.60646 5.28581 4.65578 5.63176 4.95241 5.92839L4.95236 5.92844Z" fill="#8C8C8C"/> </g> <defs> <clipPath id="clip0_769_17518"> <rect width="24" height="24" fill="white"/> </clipPath> </defs> </svg>
</template>

<script>
export default {
  name: "CloseSimple"
}
</script>

<style scoped>

</style>