<template>
  <div class="d-flex flex-column" style="padding: 40px;">
    <navigation-drawer-header @close="$emit('close')"/>
    <h1 class="text-center mt-10 mb-5">Подтверждение регистрации</h1>
    <h2 class="mt-15 font-weight-medium" style="font-weight: 500;font-size: 30px;line-height: 140%;">Ввод кода</h2>
    <p class="mt-5" style="color:#6B6B6B">Для защиты Вашего профиля мы отправили на указанный номер телефона бесплатное сообщение с кодом.</p>
    <p class="mb-2" style="font-size: 20px;line-height: 140%;letter-spacing: 0.03em;color: #000000;">{{ phone }}</p>
    <p v-if="!fromEmail" class="mt-0 mb-5" style="color: #1B7DC9;font-size: 14px; font-weight: 500; cursor: pointer" @click="$emit('changePhone')">Изменить номер телефона</p>
    <p v-else>&nbsp;</p>
    <!--    <p>{{ verificationToken }}</p>-->
<!--    <p>{{ verificationCode }}</p>-->
    <v-otp-input
        class="mt-5 mb-0"
        length="4"
        height="100px"
        :class="{ 'justify-center' : true, 'otp-error': isError || error}"
        color="primary"
        type="number"
        @finish="submit()"
        @input="resetOtpInput()"
        v-model="verificationCode"
    ></v-otp-input>
    <p v-if="error" class="red--text" v-text="error" />
    <template v-if="isError">
      <p style="font-size: 12px;" v-for="error in Object.values(errors)" :key="error">{{ error }}</p>
    </template>
    <p v-else style="font-size: 12px;">&nbsp;</p>
    <v-btn
        color="primary"
        elevation="0"
        class="mt-5"
        block
        large
        height="48px"
        :disabled="loading || !!error"
        :loading="loading"
        @click="submit()"
    >Продолжить</v-btn>
    <p class="mt-4 mb-10" v-if="countdown > 0"><span style="color: #9AA6B0;">Код отправлен, повторная отправка<br/>возможна через: </span>00:{{ countdownWithZero }}</p>
    <p class="mt-4 mb-10" v-else>Не пришёл код? <span class="link" @click="resendCode">Отправить повторно</span></p>
    <v-divider class="mt-15 mb-5"></v-divider>
    <p class="mt-0">Возникли вопросы? <span class="link" @click.prevent="openAmoChat">Написать в техническую поддержку</span></p>
  </div>
</template>

<script>
import { resendCode, verifyOTPCode } from "@/services/api";
import NavigationDrawerHeader from "@/components/Auth/ForgotPassword/NavigationDrawerHeader";
import { openAmoChat } from "@/utils";

export default {

  name: "PhoneVerificationStep",
  components: { NavigationDrawerHeader },
  props: [ 'phone', 'verificationToken' ],

  computed: {
    isError() {
      return Object.values(this.errors).length > 0
    },
    countdownWithZero() {
      return this.countdown < 10 ? "0" + this.countdown : this.countdown
    }
  },
  data: () => {
    return {
      errors: [],
      loading: false,
      verificationCode: "",
      email: "",
      fromEmail: false,
      countdown: 59,
      countdownInterval: "",
      error: ""
    }
  },
  async beforeMount() {
    this.setCountdownInterval()

    if (this.$route.query.token && this.$route.query.code) {
      this.fromEmail = true
      this.verificationCode = this.$route.query.code
      await this.submit()
    }
  },
  methods: {
    openAmoChat,

    async resendCode() {
      clearInterval(this.countdownInterval)
      //send code
      try {
        const token = await resendCode({
          token: this.verificationToken
        })
        this.$emit('changeVerificationToken', token)
        this.setCountdownInterval()
      } catch (e) {
        console.log(e)
      }
    },

    setCountdownInterval() {

      this.countdown = 59
      this.countdownInterval = setInterval(()=> {
        if (this.countdown < 1) {
          clearInterval(this.countdownInterval)
          // this.setCountdownInterval()
        }
        this.countdown -= 1
      }, 1000)
    },

    resetOtpInput() {
      if (!this.verificationCode) {
        this.errors = false;
      }
      this.error = ""
    },

    async submit() {
      try {
        this.loading = true;
        const token = await verifyOTPCode(this.verificationCode, this.verificationToken);
        this.$emit('done', token);
        this.error = "";
      } catch (error) {
        this.error = error.message;
        // this.$handleServerError(error);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>
  .v-otp-input::v-deep .v-input {
    font-size: 70px;
    border-radius: 16px;
    max-width: 90px;
    margin: 0 15px;

  }

  .v-otp-input::v-deep input {
    max-height: none;
  }


  .v-otp-input::v-deep .v-input input {
    color: var(--v-primary-base)!important;
    line-height:0.5;
  }

  /*.otp-error {*/
  /*  border-color: #FF4848!important;*/
  /*}*/


  .otp-error::v-deep .v-input input {
    color: #FF4848!important;
  }

  .otp-error::v-deep .v-input fieldset {
    border-color: #FF4848;
  }
</style>
