<template>
  <v-dialog
      v-model="isVisible"
      persistent
      max-width="450"
      overlay-color="black"
      overlay-opacity="0.64"
  >
    <v-card>
      <v-card-title>
        <slot>
          <v-icon
              size="100px"
              color="danger"
          >
            mdi-alert-circle-outline
          </v-icon>
        </slot>
      </v-card-title>

      <v-card-subtitle>
        {{ title || 'Произошла ошибка' }}
      </v-card-subtitle>

      <v-card-text>

        <slot name="text">
          {{ text }}
        </slot>

        <div v-if="errors" style="text-align: left;">
          <ul v-for="error in Object.values(errors)" :key="error">
            <li>{{ error }}</li>
          </ul>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn
            color="primary"
            elevation="0"
            large
            block
            @click="close()"
        >
          {{ ok }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {

  name: 'ErrorDialog',

  props: {
    title: {
      type: String
    },
    text: {
      type: String
    },
    ok: {
      type: String,
      default: 'Закрыть'
    },
    errors: {
      type: Object
    },
    callback: {
      type: Function
    }
  },

  data() {
    return {
      isVisible: false
    }
  },

  methods: {
    show() {
      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
      if (typeof this.callback === "function") {
        this.callback();
      }
    }
  }
}
</script>

<style scoped>
/* remove dialog shadow */
::v-deep .v-dialog {
  box-shadow: none;
}
.v-sheet.v-card {
  box-shadow: none;
}

/* dialog style */
.v-dialog > .v-card {
  padding: 20px;
  border-radius: 16px;
}
.v-dialog > .v-card > .v-card__title {
  justify-content: center;

  margin-bottom: 20px;
}
.v-dialog > .v-card > .v-card__subtitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;

  color: #1A1F24;

  word-break: break-word;

  text-align: center;
}
.v-dialog > .v-card > .v-card__text {
  padding: 10px 20px 10px 10px;

  text-align: center;
}
</style>
