<template>
  <v-app id="inspire">
    <v-app-bar app clipped-left class="elevation-0" style="background: #f6f6f8">
      <div>
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      </div>
      <span style="display: inherit" class="atis-logo">
        <img
          alt="logo"
          src="@/assets/logo.svg"
        />
      </span>
      <!-- <div class="ml-8 text-subtitle-2">
        <p class="font-weight-regular mb-0">
          Бонусы всех организаций:
          <span class="font-weight-medium">{{ bonuses }}</span>
        </p>
      </div> -->
      <v-spacer></v-spacer>

      <div class="support-link text-right ml-2" @click="onSupportClick">
        Написать в поддержку
      </div>
      <v-divider
        vertical
        class="mx-5"
        style="min-height: 50%; height: auto; align-self: center"
      ></v-divider>

      <Notifications />

      <!-- <v-btn class="ml-5" icon small disabled>
        <v-icon>mdi-cog</v-icon>
      </v-btn> -->

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon small class="mr-5 ml-5" v-bind="attrs" v-on="on">
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-list outlined>
          <v-list-item link to="/accreditation">
            <v-list-item-title>Аккредитация</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/profile">
            <v-list-item-title>Личные данные</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="logout">
            <v-list-item-title>Выйти</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      clipped
      app
      style="background: #f6f6f8"
      floating
    >
      <v-list class="d-flex flex-column" style="font-size: 14px">
        <v-list-item
          class="mt-auto"
          activeClass="gray-200 active"
          link
          to="/procedures"
        >
          <v-list-item-action>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2738_15596)">
                <path
                  opacity="0.6"
                  d="M3 13H11V3H3V13ZM13 21H21V11H13V21Z"
                  fill="currentcolor"
                />
                <g opacity="0.45">
                  <path d="M11 21H3V15H11V21Z" fill="currentcolor" />
                  <path d="M13 9V3H21V9H13Z" fill="currentcolor" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_2738_15596">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Закупки</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item activeClass="gray-200 active" link to="/organizations">
          <v-list-item-action>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2738_15616)">
                <path
                  opacity="0.45"
                  d="M8.17 5.69995L1 10.48V21H6V13H10V21H15V10.25L8.17 5.69995Z"
                  fill="currentcolor"
                />
                <path
                  opacity="0.6"
                  d="M10 3V4.51L12 5.84L13.73 7H15V7.85L17 9.19V11H19V13H17V15H19V17H17V21H23V3H10ZM19 9H17V7H19V9Z"
                  fill="currentcolor"
                />
              </g>
              <defs>
                <clipPath id="clip0_2738_15616">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Мои организации</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item activeClass="gray-200 active" link to="/finance">
          <v-list-item-action>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2738_15605)">
                <path
                  opacity="0.45"
                  d="M21 18V19C21 20.1 20.1 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.9 3.89 3 5 3H19C20.1 3 21 3.9 21 5V6H12C10.89 6 10 6.9 10 8V16C10 17.1 10.89 18 12 18H21Z"
                  fill="currentcolor"
                />
                <path
                  opacity="0.6"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 16H22V8H12V16ZM16 13.5C15.17 13.5 14.5 12.83 14.5 12C14.5 11.17 15.17 10.5 16 10.5C16.83 10.5 17.5 11.17 17.5 12C17.5 12.83 16.83 13.5 16 13.5Z"
                  fill="currentcolor"
                />
              </g>
              <defs>
                <clipPath id="clip0_2738_15605">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Финансы</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item activeClass="gray-200 active" link to="/catalog">
          <v-list-item-action>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2738_38077)">
                <path
                  opacity="0.45"
                  d="M3 15H11V13H3V15ZM3 19H11V17H3V19ZM3 11H11V9H3V11ZM3 5V7H11V5H3Z"
                  fill="currentcolor"
                />
                <path opacity="0.6" d="M21 5H13V19H21V5Z" fill="currentcolor" />
              </g>
              <defs>
                <clipPath id="clip0_2738_38077">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Каталог</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <v-list-item activeClass="gray-200 active" link to="/journal">
          <v-list-item-action>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2738_38069)">
                <path
                  opacity="0.6"
                  d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM14 17H7V15H14V17ZM17 13H7V11H17V13ZM17 9H7V7H17V9Z"
                  fill="currentcolor"
                />
              </g>
              <defs>
                <clipPath id="clip0_2738_38069">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Журнал</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item
          activeClass="gray-200 active"
          link
          to="/counterparties"
        >
          <v-list-item-action>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.6"
                d="M2.00613 12.2602L2 18.9987C2 20.1087 2.89 20.9987 4 20.9987H20C21.11 20.9987 22 20.1087 22 18.9987V12.1719C20.946 12.5689 19.6841 12.8805 18.3235 13.1131C16.3177 13.4561 14.043 13.6365 11.7918 13.6481C9.54135 13.6598 7.29824 13.5029 5.35712 13.1645C4.11456 12.9479 2.96361 12.6517 2.00613 12.2602Z"
                fill="currentcolor"
              />
              <path
                opacity="0.45"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.0004 6H20.0004C21.1104 6 22.0004 6.89 22.0004 8V10.7714C20.9994 11.2067 19.6598 11.5671 18.1048 11.833C16.1794 12.1622 13.9767 12.3381 11.7855 12.3494C9.59366 12.3608 7.42972 12.2074 5.58077 11.8851C4.10998 11.6287 2.88561 11.273 2.00781 10.8379L2.01039 8C2.01039 6.89 2.89039 6 4.00039 6H8.00039V4C8.00039 2.89 8.89039 2 10.0004 2H14.0004C15.1104 2 16.0004 2.89 16.0004 4V6ZM10.0004 6H14.0004V4H10.0004V6Z"
                fill="currentcolor"
              />
            </svg>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Контрагенты</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <v-divider class="my-2 px-2"></v-divider>

        <v-list-item activeClass="gray-200 active" link to="/analytics">
          <v-list-item-action>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.6"
                d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM9 17H7V10H9V17ZM13 17H11V7H13V17ZM17 17H15V13H17V17Z"
                fill="currentcolor"
              />
            </svg>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Аналитика</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item activeClass="gray-200 active" link to="/documents">
          <v-list-item-action>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2738_38104)">
                <path
                  opacity="0.6"
                  d="M15 5L21 11V21C21 22.1 20.1 23 19 23H7.99C6.89 23 6 22.1 6 21L6.01 7C6.01 5.9 6.9 5 8 5H15ZM14 12H19.5L14 6.5V12Z"
                  fill="currentcolor"
                />
                <path
                  opacity="0.45"
                  d="M4 1H16V3H4V17H2V3C2 1.9 2.9 1 4 1Z"
                  fill="currentcolor"
                />
              </g>
              <defs>
                <clipPath id="clip0_2738_38104">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Документы</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          activeClass="gray-200 active"
          href="https://docs.atistrade.ru"
          target="_blank"
        >
          <v-list-item-action>
            <v-icon size="24">mdi-school</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>База знаний</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container>
        <v-breadcrumbs :items="breadcrumbs" exact link>
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              :to="{ name: item.routeName, params: { ...item.routeParams } }"
              exact
              :disabled="item.routeName ? false : true"
            >
              <span
                class="text-truncate"
                style="max-width: 200px"
                :title="item.name"
                >{{ item.name }}</span
              >
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-container>
      <template v-if="$store.state.isInit">
        <div style="min-height: 80vh">
          <slot></slot>
        </div>
      </template>
      <template v-else>
        <v-overlay class="d-flex justify-center align-center">
          <v-progress-circular
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-overlay>
      </template>
      <OnboardingProgress class="lk-onboarding-progress" />
    </v-main>

    <v-overlay v-if="loading" absolute>
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>

    <ErrorDialog
      ref="errorDialog"
      :title="dialogs.error.title"
      :text="dialogs.error.text"
      :errors="dialogs.error.errors"
      :ok="dialogs.error.ok"
      :callback="dialogs.error.callback"
    ></ErrorDialog>
    <SuccessDialog
      ref="successDialog"
      :title="dialogs.success.title"
      :text="dialogs.success.text"
      :ok-text="dialogs.success.okButton"
      :redirect-path="dialogs.success.redirectPath"
      :callback="dialogs.success.callback"
    ></SuccessDialog>
    <ConfirmationDialog
      ref="deleteDialog"
      :title="dialogs.delete.title"
      :text="dialogs.delete.text"
      :callback="dialogs.delete.callback"
      :ok-text="dialogs.delete.okButton || 'Удалить'"
      ok-color="danger"
      ok-icon="mdi-delete-outline"
    ></ConfirmationDialog>
    <ConfirmationDialog
      ref="confirmDialog"
      :title="dialogs.confirm.title"
      :text="dialogs.confirm.text"
      :ok-text="dialogs.confirm.okButton"
      :callback="dialogs.confirm.callback"
      :cancel-text="dialogs.confirm.cancelButton"
      :max-width="dialogs.confirm.width"
    ></ConfirmationDialog>
  </v-app>
</template>

<script>
import ErrorDialog from "@/components/ErrorDialog";
import SuccessDialog from "@/components/NewSuccessDialog";
import { mapGetters, mapState } from "vuex";
import { isMobileDevice, openAmoChat } from "@/utils";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import { logout } from "@/services/api";
import { formatAmount } from "@/utils";
import Notifications from "@/components/Shared/Notifications/Notifications";
import OnboardingProgress from "@/components/Shared/Profile/Progress";

export default {
  name: "LKLayout",

  components: {
    ConfirmationDialog,
    ErrorDialog,
    SuccessDialog,
    Notifications,
    OnboardingProgress,
  },

  provide() {
    return {
      showError: this.showError,
      showSuccessDialog: this.showSuccessDialog,
      showDeleteDialog: this.showDeleteDialog,
      confirm: this.confirm,
      success: this.success,
      alert: this.showError,
      delete: this.delete,
    };
  },

  data: () => ({
    loading: false,
    drawer: null,
    datePicker: null,
    dialogs: {
      success: {
        text: undefined,
        title: undefined,
        okButton: undefined,
        redirectPath: undefined,
      },
      delete: {
        text: undefined,
        title: undefined,
        callback: undefined,
      },
      error: {
        text: undefined,
        title: undefined,
        errors: undefined,
        ok: undefined,
        callback: undefined,
      },
      confirm: {
        text: undefined,
        title: undefined,
        callback: undefined,
        okButton: undefined,
        cancelButton: undefined,
      },
    },
    errorDialog: false,
  }),

  computed: {
    ...mapState(["breadcrumbs"]),
    ...mapGetters({
      totalBonuses: "finance/totalBonuses",
    }),
    bonuses() {
      const bonuses = this.totalBonuses || 0;
      return formatAmount(bonuses, 0);
    },
  },

  watch: {
    $route() {
      this.$store.dispatch("buildBreadcrumbs", this.$route.meta.breadcrumbs);
    },
    "$route.path": {
      async handler(value) {
        if (isMobileDevice()) {
          const unsupportedRoute = "procedures";
          if (value.includes(unsupportedRoute)) {
            await this.$nextTick();
            this.showError(
              `Использование функционала этого раздела личного кабинета доступно только из браузера на компьютере или ноутбуке.
             Через мобильный можно перейти в разделы: "Мои организации", "Финансы", "Аналитика", "Документы" и "База знаний"`
            ).then(() => {
              this.$router.push({
                path: "/organizations",
              });
            });
          }
        }
      },
      immediate: true,
    },
  },

  async beforeMount() {
    this.$store.dispatch("buildBreadcrumbs", this.$route.meta.breadcrumbs);

    this.$store.dispatch("finance/setTotalBonuses");
  },

  mounted() {
    // if (isMobileDevice()) {
    //   this.showError(
    //     "Использование функционала платформы доступно только из браузера на компьютере"
    //   ).then(() => {
    //     this.$router.push({
    //       path: "/organizations",
    //     });
    //   });
    // }
  },

  methods: {
    showError(title, params = {}) {
      return new Promise((resolve) => {
        this.dialogs.error = {
          title: title,
          text: params.text,
          ok: params.ok,
          errors: params.errors,
          callback: resolve,
        };
        this.$refs.errorDialog.show();
      });
    },

    showSuccessDialog(data) {
      this.dialogs.success = { ...data };
      this.$nextTick(() => {
        this.$refs.successDialog.show();
      });
    },

    showDeleteDialog(data) {
      this.dialogs.delete = { ...data };
      this.$nextTick(() => {
        this.$refs.deleteDialog.show();
      });
    },

    /** @return Promise */
    delete(title, params = {}) {
      if (typeof params === "string") {
        params = { text: params };
      }

      return new Promise((resolve) => {
        this.dialogs.delete = {
          title: title,
          text: params.text,
          okButton: params.okButtonText,
          isCancelCbk: params.isCancelCbk,
          callback: resolve,
        };
        this.$refs.deleteDialog.show(params);
      });
    },

    /** @return Promise */
    confirm(title, text = null, ok = "ОК", cancel = "Отмена", params = {}) {
      if (typeof title === "object") {
        params = title;
        title = params.title;
        text = params.text;
        ok = params.ok;
        cancel = params.cancel;
      }

      return new Promise((resolve) => {
        this.dialogs.confirm = {
          title: title,
          text: text,
          okButton: ok,
          cancelButton: cancel,
          isCancelCbk: params.isCancelCbk,
          callback: resolve,
        };
        this.$refs.confirmDialog.show(params);
      });
    },

    /** @return Promise */
    success(title, params = {}) {
      if (typeof params === "string") {
        params = { text: params };
      }

      return new Promise((resolve) => {
        this.dialogs.success = {
          title: title,
          text: params.text,
          okButton: params.okButtonText,
          callback: resolve,
        };
        this.$refs.successDialog.show();
      });
    },

    logout() {
      this.loading = true;

      logout().finally(() => {
        this.$store.dispatch("logout");
        window.location.href = "/";
      });
    },

    onSupportClick() {
      openAmoChat();
    },
  },
};
</script>

<style lang="scss">
.v-list-item__title {
  font-size: 14px !important;
}

/*h1 {*/
/*  font-size: 3rem;*/
/*}*/

.support-link {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #8c8c8c !important;
  text-decoration: none;
  cursor: pointer;
}

.active {
  color: #1b7dc9 !important;
}

.v-breadcrumbs {
  padding-left: 0 !important;
}
@media (min-width: 1904px) {
  .v-breadcrumbs {
    padding-top: 40px;
  }
}
.v-breadcrumbs .v-breadcrumbs__item {
  font-size: 13px;
  font-weight: 400;

  color: inherit;
}
.v-breadcrumbs .v-breadcrumbs__item:hover {
  text-decoration: underline;
}

.lk-onboarding-progress {
  position: sticky;
  bottom: 0;
  left: 0;
  padding: 20px;
  z-index: 10;
  justify-content: flex-start;
}
.atis-logo {
  padding: 12px 24px 12px 0px;
}
@media (max-width: 768px) {
  .atis-logo {
    max-width: 100px;
    width: 100%;
    overflow: hidden;
    padding: 4px 8px 4px 0;
    img {
      max-width: 100px;
    }
  }
}
</style>
