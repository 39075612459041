<template>
  <div class="d-flex flex-column">
    <navigation-drawer-header @close="$emit('close')" style="margin-bottom: 90px;"/>

    <h1 style="font-size: 32px; margin-bottom: 15px;">Придумайте новый пароль</h1>
    <div style="margin-bottom: 90px;">Для защиты ваших данных необходимо придумать<br>безопасный пароль.</div>

    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-form @submit.prevent>
        <ValidationProvider
            :rules="{ required: true, max: 18 }"
            v-slot="{ errors }"
            name="новый пароль"
        >
          <v-text-field
              v-model="password"
              color="primary"
              outlined
              rounded
              dense
              required
              class="mb-0 mt-10"
              placeholder="Придумайте пароль"
              label="Придумайте пароль"
              :append-icon="passwordValue ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (passwordValue = !passwordValue)"
              :type="passwordValue ? 'password' : 'text'"
              :error-messages="errors"
          ></v-text-field>
          <div style="text-align: left; margin-bottom: 40px;">
            <div style="margin-bottom: 10px; font-size: 16px;">Он должен содержать:</div>
            <div style="color: #494949; font-size: 14px; margin-bottom: 8px;"><PasswordCircle :fill="passwordsValidations.length ? '#7CC156' : undefined" class="mr-2"></PasswordCircle>6 и более символов</div>
            <div style="color: #494949; font-size: 14px; margin-bottom: 8px;"><PasswordCircle :fill="passwordsValidations.uppers ? '#7CC156' : undefined" class="mr-2"></PasswordCircle>заглавные латинские буквы</div>
            <div style="color: #494949; font-size: 14px; margin-bottom: 8px;"><PasswordCircle :fill="passwordsValidations.lowers ? '#7CC156' : undefined" class="mr-2"></PasswordCircle>строчные латинские буквы</div>
            <div style="color: #494949; font-size: 14px; margin-bottom: 8px;"><PasswordCircle :fill="passwordsValidations.numbers ? '#7CC156' : undefined" class="mr-2"></PasswordCircle>цифры</div>
          </div>
        </ValidationProvider>
        <v-btn
            color="primary"
            elevation="0"
            block
            large
            height="48px"
            :disabled="invalid || !passwordIsValid"
            :loading="loading"
            @click="setPassword()"
        >Продолжить</v-btn>
      </v-form>
    </ValidationObserver>

  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { resetPassword } from "@/services/api";
import NavigationDrawerHeader from "@/components/Auth/ForgotPassword/NavigationDrawerHeader";
import { PASSWORD_LOWER_WORDS_REGEXP, PASSWORD_NUMBERS_REGEXP, PASSWORD_UPPER_WORDS_REGEXP } from "@/const";
import PasswordCircle from "@/components/Icons/PasswordCircle";

export default {
  name: "CreatePasswordStep",
  components: {
    PasswordCircle,
    NavigationDrawerHeader,
    ValidationProvider,
    ValidationObserver
  },
  props: [ 'phone', 'token' ],
  computed: {
    passwordsValidations() {
      const password = this.password ?? '';

      return {
        length: password.length >= 6 && password.length <= 18,
        uppers: PASSWORD_UPPER_WORDS_REGEXP.test(password),
        lowers: PASSWORD_LOWER_WORDS_REGEXP.test(password),
        numbers: PASSWORD_NUMBERS_REGEXP.test(password),
      }
    },
    passwordIsValid() {
      return Object.values(this.passwordsValidations).every(Boolean)
    }
  },
  data: () => {
    return {
      passwordValue: "1",
      password: "",
      loading: false
    }
  },
  methods: {
    async setPassword() {
      this.loading = true;

      try {
        await resetPassword({ token: this.token, phone: this.phone, password: this.password });
        this.$emit('done');
      } catch (error) {
        this.$handleServerError(error);
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
