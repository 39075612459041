<template>
  <v-text-field
      v-bind="computedProps"
      v-on="$listeners"
      v-model="displayValue"
      @input="onPhoneInput"
      @focus="onPhoneFocus"
      @paste="onPhonePaste"
  >
    <template #append>
      <slot name="append"></slot>
    </template>
  </v-text-field>
</template>

<script>
import { VTextField } from 'vuetify/lib'

export default {

  name: 'PhoneInput',

  extends: VTextField,

  props: [ 'value' ],

  data() {
    return {
      displayValue: this.formatPhone(String(this.value ?? ''))
    }
  },

  watch: {
    value(value) {
      this.$nextTick(() => {
        this.displayValue = this.formatPhone(value);
        this.$emit("input", this.displayValue);
      });
    }
  },

  computed: {
    computedProps: function () {
      return {
        ...this.$props
      }
    }
  },

  methods: {
    onPhoneInput() {
    },

    onPhoneFocus() {
      if (!this.value || this.value.length === 0) {
        this.displayValue = '+7';
      }
    },

    onPhonePaste() {
    },

    filterDigits(value) {
      return value.replace(/\D/g, "") ?? ''; // keep only digits
    },

    formatPhone(value) {
      value = this.filterDigits(value);

      let formatted = '';
      for (let i = 0, l = Math.min(value.length, 11); i < l; i++) {
        switch (i) {
          case 0:
            formatted += '+7';
            break;
          case 1:
            formatted += ` (${value[i]}`
            break;
          case 3:
            formatted += `${value[i]}`
            break;
          case 4:
            formatted += `) ${value[i]}`
            break;
          case 7:
          case 9:
            formatted += ` ${value[i]}`
            break;
          default:
            formatted += value[i];
            break;
        }
      }

      return formatted;
    }
  }
}
</script>
