<template>
  <v-app app>
    <v-main style="background: #F6F6F8;">
      <v-container>
        <v-row>
          <v-col cols="6" style="display: flex; align-items: center;">
            <slot></slot>
          </v-col>
          <v-col cols="6">
            <img src="@/assets/kreslo.png" alt="kreslo" class="mt-15">
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "EmptyLayout"
};
</script>

<style scoped>

</style>