<template>
  <v-dialog v-model="dialog" max-width="1000" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <slot />
      </span>
    </template>

    <v-list v-if="initialLoading">
      <div style="text-align: center;" class="my-5">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
    </v-list>

    <v-card v-else>
      <v-card-title>
        <div style="width: 100%; display: flex; justify-content: space-between; align-items: start; gap: 10px;">
          <div>
            <h3 class="text-h4 font-weight-medium">Уведомления</h3>
            <p class="mb-0 text-subtitle-1 font-weight-medium">
              ({{ total }}
              {{ pluralize(total, ["уведомление", "уведомления", "уведомлений"]) }})
            </p>
          </div>
          <div style="cursor: pointer;" @click="dialog = false">
            <CloseSimple/>
          </div>
        </div>
      </v-card-title>

      <div style="position: relative;">
        <v-progress-linear
            :active="lazyLoading"
            indeterminate
            absolute
            color="primary"
        ></v-progress-linear>
      </div>

      <v-card-text class="notification-card">
        <v-list>
          <v-list-item
            v-for="(notification, i) in notifications"
            :key="i"
            style="min-height: unset"
            ref="notificationItem"
            :id="notification.id"
            :data-unread="notification.unread"
          >
            <v-container fluid class="pa-0">
              <v-divider class="mb-4 mt-4" v-if="i > 0"/>
              <div class="d-flex">
                <span
                  :class="[notification.unread ? 'unread' : 'read']"
                  class="round"
                />
                <p
                  class="ml-2 mb-0 black--text text-subtitle-2"
                  :class="[notification.unread ? null : 'font-weight-regular']"
                >
                  {{ notification.text }}
                </p>
              </div>
              <p
                class="text-subtitle-2 secondary--text font-weight-regular ml-4 mb-0"
              >
                {{ formatDateStandart(notification.date, true, true, true) }}
              </p>
            </v-container>
          </v-list-item>
        </v-list>

        <!-- When this element is visible on the screen, we load the next page -->
        <div v-intersect="infiniteScrolling"></div>

        <div v-if="lazyLoading" style="text-align: center; margin-bottom: 20px;">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { listNotifications, markNotificationsAsRead } from "@/services/api";
import { formatDateStandart, pluralize } from "@/utils";
import CloseSimple from "@/components/Icons/CloseSimple";

export default {

  components: {
    CloseSimple
  },

  data() {
    return {
      dialog: false,
      initialLoading: true,
      notifications: [],
      observerLock: false,
      loading: false,
      lazyLoading: false,
      limit: 10,
      page: 1,
      total: 0,
    };
  },

  methods: {

    formatDateStandart,

    pluralize,

    async open() {
      this.dialog = true;

      this.observerLock = true;

      // load notifications
      this.initialLoading = true;
      this.page = 1;
      const notifications = await this.loadNotificationsPage(this.page);
      this.initialLoading = false;

      window.setTimeout(() => this.observerLock = false, 100);

      // mark loaded notifications as read
      this.markNotificationsAsRead(notifications);
    },

    async loadNotificationsPage(page = this.page, limit = this.limit) {
      try {
        this.loading = true;
        const result = await listNotifications(page, limit);
        this.total = result.total;
        this.notifications.push(...result.items);
        return result.items;
      } catch (error) {
        this.$handleServerError(error);
      } finally {
        this.loading = false;
      }
    },

    async markNotificationsAsRead(notifications) {
      await markNotificationsAsRead(notifications.map((n) => n.id));
      notifications.forEach(n => n.unread = false);
    },

    async infiniteScrolling() {
      if (this.observerLock) {
        return;
      }

      this.observerLock = true;

      console.log('trigger');

      // check if there is more pages
      const lastPage = Math.ceil(this.total / this.limit);
      if (lastPage < this.page) {
        return;
      }
      // load next notification page
      this.page++;

      // load notifications
      this.lazyLoading = true;
      const notifications = await this.loadNotificationsPage(this.page);
      this.lazyLoading = false;

      window.setTimeout(() => this.observerLock = false, 100);

      // mark loaded notifications as read
      this.markNotificationsAsRead(notifications);
    }
  },
};
</script>

<style src="./notifications.scss" lang="scss" scoped />
