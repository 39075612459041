<template>
  <v-navigation-drawer
      v-model="opened"
      fixed
      temporary
      width="595"
      right
      class="text-center d-flex login-drawer"
      height="100%"
      style="padding: 20px;"
  >
    <LoginForm @close="opened = false" @forgot="$emit('forgot')" @register="$emit('register')"></LoginForm>
  </v-navigation-drawer>
</template>

<script>
import LoginForm from "@/components/Auth/Authorization/Forms/LoginForm";

export default {

  name: "LoginDrawer",

  props: ['visible'],

  components: {
    LoginForm
  },

  data: () => {
    return {
      passwordValue: String,
      forgotPassword: false
    }
  },

  computed: {
    opened: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('update:visible', value)
      }
    }
  }
}
</script>

<style lang="scss">
  .login-drawer .v-navigation-drawer__content {
    padding: 20px;
  }
</style>