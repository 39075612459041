import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from '@/store';
import "./plugins/vee-validate";
import GlobalErrorHandlerMixin from "@/mixins/GlobalErrorHandlerMixin";

import './assets/stylesheet.scss';
import './scss/index.scss';
import './scss/lk.scss';

Vue.config.productionTip = false;

Vue.mixin(GlobalErrorHandlerMixin);

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
