<template>
  <div style="padding: 40px; display: flex; flex-direction: column; height: 100%;">
    <navigation-drawer-header @close="$emit('close')"/>
    <h1 class="text-center mt-3">Регистрация в системе</h1>
    <p class="mt-1 mb-8" style="color: #494949;">
      Представьтесь и укажите номер телефона,<br>
      на который поступит код для регистрации
    </p>

    <v-overlay
        v-if="inviteLoading"
        absolute
    >
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>

    <ValidationObserver ref="observer" v-slot="{ pending, invalid }">
      <v-form @submit.prevent="onSubmit" @input="handleInput" ref="form">

        <ValidationProvider
            vid="last_name"
            rules="required|cyrillic|max:40"
            v-slot="{ errors }"
            name="фамилия"
        >
          <v-text-field
              v-model="model.lastName"
              color="primary"
              outlined
              rounded
              dense
              placeholder="Фамилия*"
              label="Фамилия"
              :hide-details="false"
              :error-messages="errors"
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider
            vid="name"
            rules="required|cyrillic|max:40"
            v-slot="{ errors }"
            name="имя"
        >
          <v-text-field
              v-model="model.name"
              color="primary"
              outlined
              rounded
              dense
              placeholder="Имя*"
              label="Имя"
              :error-messages="errors"
              :hide-details="false"
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider
            vid="middle_name"
            rules="cyrillic|max:40"
            v-slot="{ errors }"
            name="отчество"
        >
          <v-text-field
              color="primary"
              outlined
              rounded
              dense
              placeholder="Отчество (при наличии)"
              label="Отчество"
              :hide-details="false"
              :error-messages="errors"
              v-model="model.middleName"
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider
            vid="occupation"
            rules="required|cyrillicOrLatin|max:40"
            v-slot="{ errors }"
            name="должность"
        >
          <v-text-field
              v-model="model.occupation"
              color="primary"
              outlined
              rounded
              dense
              placeholder="Должность*"
              label="Должность"
              :hide-details="false"
              :error-messages="errors"
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider
            vid="email"
            rules="required|email|latin|max:74|email_unique"
            v-slot="{ errors }"
            name="e-mail"
        >
          <v-text-field
              color="primary"
              outlined
              rounded
              dense
              placeholder="E-mail*"
              label="E-mail"
              :hide-details="false"
              :error-messages="errors"
              v-model="model.email"
              :readonly="inviteApplied"
              :disabled="inviteApplied"
          >
          </v-text-field>
        </ValidationProvider>

        <ValidationProvider
            vid="phone"
            rules="required|phone|phone_unique"
            v-slot="{ errors }"
            name="номер телефона"
        >
          <phone-input
              v-model="model.phone"
              color="primary"
              outlined
              rounded
              dense
              label="Номер мобильного телефона"
              :hide-details="false"
              :error-messages="errors"
              type="tel"
          >
            <template v-slot:append>
              <v-tooltip top min-width="260px" max-width="260px">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      style="opacity: 0.5; cursor: default;"
                      v-bind="attrs"
                      v-on="on"
                  >
                    mdi-information
                  </v-icon>
                </template>
                <div style="font-size: 14px; line-height: 18px; text-align: center;">На этот номер телефона поступит код для авторизации</div>
              </v-tooltip>
            </template>
          </phone-input>
        </ValidationProvider>

        <div class="text-left">
          <div style="margin-bottom: 10px;">
            <v-checkbox
                class="my-0 align-start justify-start"
                v-model="model.terms"
                hide-details
            >
              <template v-slot:label>
              <span class="agreement">
                Я принимаю условия
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                        target="_blank"
                        href="/pdf/offer_agreement.pdf"
                        @click.stop
                        v-on="on"
                    >
                      Публичной оферты
                    </a>
                  </template>
                  Откроется в новом окне
                </v-tooltip>
                и обязуюсь соблюдать
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                        target="_blank"
                        href="/pdf/rules_of_procedure.pdf"
                        @click.stop
                        v-on="on"
                    >
                      Регламент онлайн-платформы ООО "АтисТрейд"
                    </a>
                  </template>
                  Откроется в новом окне
                </v-tooltip>
              </span>
              </template>
            </v-checkbox>
          </div>

          <div style="margin-bottom: 10px;">
            <v-checkbox
                class="my-0 align-start justify-start"
                v-model="model.personalData"
                hide-details
            >
              <template v-slot:label>
              <span class="agreement">
                Я даю
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                        target="_blank"
                        href="./pdf/personal-data.pdf"
                        @click.stop
                        v-on="on"
                    >
                      согласие на обработку моих персональных данных
                    </a>
                  </template>
                  Откроется в новом окне
                </v-tooltip>
              </span>
              </template>
            </v-checkbox>
          </div>

          <div style="margin-bottom: 10px;">
            <v-checkbox
                class="my-0"
                v-model="model.advertising"
                hide-details
            >
              <template v-slot:label>
                <div class="agreement">
                  Я даю
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a
                          target="_blank"
                          href="./pdf/advertising.pdf"
                          @click.stop
                          v-on="on"
                      >
                        согласие на получение сообщений рекламного характера и иных информационных сообщений
                      </a>
                    </template>
                    Откроется в новом окне
                  </v-tooltip>
                  <br>
                  Никакого спама, только польза!</div>
              </template>
            </v-checkbox>
          </div>
        </div>

        <v-btn
            color="primary"
            elevation="0"
            block
            large
            height="48px"
            :disabled="pending || invalid || loading || !model.terms || !model.personalData"
            type="submit"
            :loading="pending || loading"
        >
          Зарегистрироваться
        </v-btn>
      </v-form>
    </ValidationObserver>

    <v-spacer/>
    <v-divider style="margin-bottom: 10px; margin-top: 10px;"></v-divider>

    <div>
      <div style="margin-bottom: 10px;">Уже регистрировались? <span class="link" style="font-size: 14px; cursor: pointer;" @click="$emit('login')">Войти</span></div>
      <div>Возникли вопросы? <span class="link" style="font-size: 14px; cursor: pointer;" @click.prevent="openAmoChat">Написать в техническую поддержку</span></div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapState } from 'vuex'
import { getInvitedEmployee } from "@/services/api";
import PhoneInput from "@/components/PhoneInput";
import NavigationDrawerHeader from "@/components/Auth/ForgotPassword/NavigationDrawerHeader";
import { openAmoChat } from "@/utils";

export default {

  name: "RegistrationStep",

  props: {
    value: {
      type: Object
    },
    loading: {
      type: Boolean
    }
  },

  components: {
    NavigationDrawerHeader,
    PhoneInput,
    ValidationObserver,
    ValidationProvider,
  },

  data() {
    return {
      inviteLoading: false,
      inviteApplied: false,

      // component variables
      //...

      // registration data
      model: {
        email: this.value?.email ?? "",
        name: this.value?.name ?? "",
        role: this.value?.role ?? "",
        lastName: this.value?.lastName ?? "",
        middleName: this.value?.middleName ?? "",
        occupation: this.value?.occupation ?? "",
        phone: this.value?.phone ?? "",
        terms: this.value?.terms ?? false,
        personalData: this.value?.personalData ?? false,
        advertising: this.value?.advertising ?? false,
        invite: this.$route.params.invite
      }
    }
  },

  computed: mapState([
    'auth'
  ]),

  beforeMount() {
    const invite = this.$route.query.invite; // employee invite
    if (invite) {

      this.inviteLoading = true;

      getInvitedEmployee(invite)
          .then(employee => {
            this.model.name = employee.name;
            this.model.lastName = employee.last_name;
            this.model.middleName = employee.middle_name;
            this.model.occupation = employee.occupation;
            this.model.role = employee.role;
            this.model.phone = employee.phone;
            this.model.email = employee.email;
            this.inviteApplied = true;
          })
          .catch(error => {
            this.$handleServerError(error);
          })
          .finally(() => {
            this.inviteLoading = false;
          });
    }
  },

  methods: {
    openAmoChat,

    async onSubmit() {
      const valid = await this.$refs.observer.validate();
      if (valid) {
        this.$emit('submit', this.model);
      }
    },

    handleInput() {
      this.$emit('input', this.model);
    }
  }
}
</script>

<style scoped>
.agreement {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #4B4B4B;
}
.agreement a {
  font-weight: 500;
  text-decoration: none;
}
.agreement a:hover {
  opacity: 0.8;
}
.agreement a:active {
  opacity: 0.5;
}

::v-deep .v-input .v-progress-linear {
  left: 20px;
  width: calc(100% - 40px);
}
</style>
