import BackendError from "@/models/BackendError";
import BackendValidationError from "@/models/BackendValidationError";
import { AxiosError } from "axios";
import { eventBus } from "@/events";

export default {
    methods: {
        $handleServerError(error, observer) {
            console.error(error);

            if (error instanceof BackendError) {
                if (error instanceof BackendValidationError) {
                    if (observer) {
                        observer.setErrors(error.fields);
                        // if at least one error field is not in observer
                        for (const [field, field_error] of Object.entries(error.fields)) {
                            if (!(field in observer.fields)) {
                                eventBus.$emit('error', field_error);
                                break; // stop looping, show the first message
                            }
                        }
                    } else {
                        // show backend validation error
                        eventBus.$emit('error', error.message /*, { errors: error.fields }*/);
                    }
                } else {
                    // show backend error
                    if (error.status === 403 || error.code === -3 /* NOT FOUND */) {
                        eventBus.$emit('error', { title: error.message, text: error.details });
                    } else if (error.status === 413) {
                        eventBus.$emit('error', { title: 'Слишком большой размер файла', text: 'Размер файла не должен превышать 8мб' });
                    } else {
                        eventBus.$emit('error', { title: error.message, text: error.details });
                    }
                }
            } else {
                // show Axios error
                if (error instanceof AxiosError) {
                    switch (error.code) {
                        case "ERR_NETWORK":
                            eventBus.$emit('error', { title: "Неизвестная ошибка сети" });
                            break;
                        case "ERR_BAD_REQUEST":
                            if (error.response.status === 413) {
                                eventBus.$emit('error', { title: 'Слишком большой размер файла', text: 'Размер файла не должен превышать 8мб' });
                            } else {
                                eventBus.$emit('error', { title: error.message });
                            }
                            break;
                        default:
                            eventBus.$emit('error', { title: error.message });
                            break;
                    }
                } else {
                    eventBus.$emit('error', { title: error.message });
                }
            }
        }
    }
}
