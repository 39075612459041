<template>
  <div style="position: relative; height: 50px; display: flex; align-items: center; justify-content: center;">
    <img src="@/assets/logo.svg" alt="logo" class="mx-auto">

    <div style="position: absolute; right: 0; cursor: pointer;" @click="$emit('close')">
      <CloseIcon></CloseIcon>
    </div>
  </div>
</template>
<script>
import CloseIcon from "@/components/Icons/Close";

export default {
  components: { CloseIcon }
}
</script>