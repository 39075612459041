<template>
  <div v-if="isProgressVisible" class="progress" :class="{ active: isOpened }">
    <v-expansion-panels v-model="panel">
      <v-expansion-panel class="progress__wrapper">
        <v-expansion-panel-header
          class="progress__header"
          :color="isOpened ? null : '#D5E8FD'"
          :class="isOpened ? 'py-6' : 'py-4'"
          @click="onProgressClick"
        >
          <h6
            class="font-weight-medium"
            :class="[isOpened ? 'text-h6' : 'text-subtitle-2']"
            style="color: #1a1f24"
          >
            Заполните профиль
          </h6>
          <template #actions>
            <v-icon color="#1A1F24">mdi-chevron-up</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="progress__body">
            <div class="progress__bar">
              <div
                class="progress__bar-active"
                :style="{ width: `${progress}%` }"
              >
                <div
                  class="d-flex align-center justify-center"
                  style="width: 100%"
                >
                  <span>{{ progress }}%</span>
                </div>
                <v-icon
                  class="progress__bar-active__icon ml-auto"
                  color="white"
                  size="16"
                  >mdi-chevron-right</v-icon
                >
              </div>
            </div>
            <div
              v-for="step in steps"
              :key="step.title"
              class="d-flex align-start mb-4"
            >
              <div
                style="border-radius: 50%"
                class="pa-1 d-flex align-center justify-content-center mr-2"
                :style="{
                  backgroundColor: step.completed ? '#1B7DC9' : '#8DBEE4',
                }"
              >
                <v-icon
                  :color="step.completed ? 'white' : 'transparent'"
                  size="14"
                  >mdi-check</v-icon
                >
              </div>
              <div class="progress__link text-subtitle-2 font-weight-regular">
                <router-link
                  v-if="step.action_url"
                  :to="step.action_url"
                  >{{ step.title }}</router-link
                >
                <span v-else>{{ step.title }}</span>
                <div
                  v-if="step.instruction_video_url"
                  class="mt-1 text-caption primary--text"
                  style="cursor: pointer"
                  @click="showInstruction(step)"
                >
                  Посмотреть инструкцию
                </div>
              </div>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <InstructionModal
      v-model="instructionModal"
      :instructionUrl="instructionUrl"
      :instructionTitle="instructionTitle"
    />
  </div>
</template>

<script>
import InstructionModal from "./InstructionModal.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    InstructionModal,
  },
  data() {
    return {
      panel: 0,
      instructionModal: false,
      instructionUrl: "",
      instructionTitle: "",
      lsKey: "progressLastClosedAt",
      maximumHoldHours: 48,
    };
  },
  computed: {
    ...mapGetters({
      steps: "progress/steps",
      progress: "progress/progress",
      isOnboardingDone: "isOnboardingDone",
    }),
    isOpened() {
      return this.panel === 0;
    },
    isProgressVisible() {
      return !this.isOnboardingDone && this.steps.length && this.progress < 100;
    },
  },
  watch: {
    $route: {
      async handler() {
        if (!this.isOnboardingDone) {
          this.$store.dispatch("progress/setSteps");
        }
      },
      deep: true,
    },
    isOnboardingDone(isDone) {
      if (!isDone) {
        this.$store.dispatch("progress/setSteps");
      }
    },
  },
  methods: {
    async onProgressClick() {
      // wait for updating expansion panel state
      await this.$nextTick();
      if (this.isOpened) localStorage.removeItem(this.lsKey);
      else this.setLastCloseTime();
    },
    setLastCloseTime() {
      localStorage.setItem(this.lsKey, JSON.stringify(new Date()));
    },
    showHoldExpiredProgress() {
      const lsValue = localStorage.getItem(this.lsKey);
      if (lsValue) {
        const lastOpenedAt = new Date(JSON.parse(lsValue));
        const hourDifference = Math.abs(new Date() - lastOpenedAt) / 36e5;
        if (hourDifference > this.maximumHoldHours) this.panel = 0;
        else this.panel = null;
      }
    },
    showInstruction(step) {
      this.instructionModal = true;
      this.instructionUrl = step.instruction_video_url;
      this.instructionTitle = step.title;
    },
  },
  mounted() {
    this.showHoldExpiredProgress();
  },
};
</script>

<style lang="scss" scoped>
.progress {
  &__wrapper {
    border-radius: 24px !important;
    border: none;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.12), 0 32px 64px rgba(0, 0, 0, 0.14);
  }
  &__header {
    border-radius: 24px !important;
    border: none;
  }
  &__bar {
    height: 30px;
    width: 100%;
    background: #d5e8fd;
    border-radius: 24px;
    margin-bottom: 24px;
    position: relative;
    &-active {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: #1b7dc9;
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: 500;
      font-size: 13px;
      line-height: 1;
      &__icon {
        position: absolute;
        right: 2px;
      }
    }
  }
  &__link {
    color: rgba(0, 0, 0, 0.87);
    cursor: default;
    & > a {
      color: inherit;
      text-decoration: none;
      display: block;
    }
    &:hover {
      color: #000000;
    }
  }
}

.progress {
  max-width: 270px;
  transition: max-width 200ms;
}
.progress.active {
  .progress__body {
    width: 270px;
  }
  max-width: 374px;
}
</style>
