import { getOnboardingProgress } from "@/services/api";

const progress = {
  namespaced: true,
  state: () => ({
    steps: [],
    progress: 0,
  }),
  actions: {
    async setSteps({ commit }) {
      try {
        const { steps, progress } = await getOnboardingProgress();
        commit("SET_STEPS", steps);
        commit("SET_PROGRESS", progress);
      } catch (err) {
        commit("SET_STEPS", []);
        commit("SET_PROGRESS", 0);
      }
    },
  },
  mutations: {
    SET_STEPS(state, steps) {
      state.steps = steps;
    },
    SET_PROGRESS(state, progress) {
      state.progress = progress;
    },
  },
  getters: {
    steps: (state) => state.steps,
    progress: (state) => state.progress,
  },
};

export default progress;
