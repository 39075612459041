<template>
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="5" cy="5" r="5" :fill="fill"/>
  </svg>
</template>

<script>
export default {
  name: "PasswordCircle",
  props: {
    fill: {
      type: String,
      default: '#8290A1'
    }
  }
}
</script>

<style scoped>

</style>