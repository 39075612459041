<template>
  <v-dialog v-model="modal" width="65%" persistent>
    <v-card>
      <div
        class="ml-auto instruction-close text-right d-inline-block"
        @click="modal = false"
      >
        <v-icon color="black" @click="modal = false" size="36" :ripple="false"
          >mdi-close</v-icon
        >
      </div>

      <v-card-text class="px-0 py-0">
        <v-responsive :aspect-ratio="16 / 9" v-if="instructionUrl">
          <iframe
            :src="src"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </v-responsive>
        <p v-else>Видео не найдено</p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    instructionUrl: {
      type: String,
      default: "",
    },
    instructionTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      modal: false,
      src: "",
    };
  },
  watch: {
    value(isOpened) {
      this.modal = isOpened;
    },
    modal(isOpened) {
      if (isOpened) {
        this.src = this.instructionUrl;
      } else {
        this.src = "";
      }
      this.$emit("input", isOpened);
    },
  },
};
</script>

<style lang="scss" scoped>
iframe,
object,
embed {
  width: 100%;
  height: 100%;
}
::v-deep .v-dialog {
  box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.75);
  position: relative;
  overflow-y: visible;
  border-radius: 5px;
}
.instruction-close {
  position: absolute;
  right: -9px;
  top: -40px;
}
::v-deep .v-icon.v-icon::after {
  // disable ripple
  width: 0;
}
</style>
