<template>
  <v-dialog
      v-model="shown"
      persistent
      :max-width="width"
      overlay-color="black"
      overlay-opacity="0.64"
  >
    <v-card>
      <v-card-title>
        <v-icon v-if="!type || type === 'error'" size="100px" color="danger">mdi-alert-circle-outline</v-icon>
      </v-card-title>
      <v-card-subtitle>{{ title ?? 'Произошла ошибка' }}</v-card-subtitle>
      <v-card-text>
        <div v-if="text" v-html="text" style="white-space: pre-line;"/>

        <div v-if="errors" style="text-align: left;">
          <ul v-for="error in Object.values(errors)" :key="error">
            <li>{{ error }}</li>
          </ul>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
            color="primary"
            elevation="0"
            large
            block
            @click="close()"
        >
          {{ ok ?? "Закрыть" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {

  data() {
    return {
      shown: false,
      type: undefined,
      title: undefined,
      text: undefined,
      ok: undefined,
      errors: undefined,
      callback: null,
      width: 450
    }
  },

  methods: {
    show(params) {
      if (typeof params === "string") {
        this.title = params;
        this.text = '';
      } else {
        this.type = params.type;
        this.title = params.title;
        this.text = params.text;
        this.ok = params.ok;
        this.errors = params.errors;
        this.width = params.width ?? 450;
      }

      this.shown = true;

      return new Promise(resolve => this.callback = resolve);
    },

    close() {
      this.shown = false;

      this.callback?.call(); // resolve a promise returned in show() method
    }
  }
}
</script>

<style scoped>
/* remove dialog shadow */
::v-deep .v-dialog {
  box-shadow: none;
}
.v-sheet.v-card {
  box-shadow: none;
}

/* dialog style */
.v-dialog > .v-card {
  padding: 20px;
  border-radius: 16px;
}
.v-dialog > .v-card > .v-card__title {
  justify-content: center;

  margin-bottom: 20px;
}
.v-dialog > .v-card > .v-card__subtitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;

  color: #1A1F24;

  word-break: break-word;

  text-align: center;
}
.v-dialog > .v-card > .v-card__text {
  padding: 10px 20px 10px 10px;

  text-align: center;
}
</style>
