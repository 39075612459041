export default class BackendError extends Error {

    constructor(message, details, status, code) {
        super(message);
        this.details = details;
        this.name = "BackendError";
        this.status = status;
        this.code = code;
    }
}
