<template>
  <v-dialog
    v-model="visible"
    :max-width="width || maxWidth"
    overlay-color="black"
    overlay-opacity="0.64"
    :persistent="isCancelCbk || persistent"
  >
    <v-card
      :style="{
        padding: dialogPadding + 'px',
      }"
    >
      <v-card-title
        :style="{
          'background-color': headerBg ? headerBg : null,
        }"
        class="px-4 px-md-10"
      >
        <div style="width: 100%; display: flex; justify-content: space-between; align-items: start; gap: 10px;">

          {{ title }}

          <div style="cursor: pointer;" @click="cancel()">
            <CloseSimple/>
          </div>
        </div>
      </v-card-title>
      <v-card-text class="px-4 px-md-10">
        <slot><span v-html="text"></span></slot>
      </v-card-text>
      <v-card-actions
        :style="{
          'background-color': footerBg ? footerBg : null,
        }"
        class="px-4 px-md-10"
      >
        <v-btn
          large
          outlined
          style="flex: 1"
          :color="cancelColor"
          class="mr-5"
          @click="cancel()"
        >
          {{ cancelText }}
        </v-btn>
        <v-btn
          large
          :disabled="disabled"
          :color="okColor"
          elevation="0"
          style="flex: 1"
          @click="confirm()"
          :loading="loading || mutLoading"
        >
          {{ okText }}
          <v-icon v-if="okIcon" style="margin-left: 5px">{{ okIcon }}</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CloseSimple from "@/components/Icons/CloseSimple";
export default {
  name: "ConfirmationDialog",
  components: { CloseSimple },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Подтверждение",
    },
    text: {
      type: String,
      default: "Подтвердите действие",
    },
    dialogPadding: {
      type: Number,
      default: 20,
    },
    cancelText: {
      type: String,
      default: "Отменить",
    },
    cancelColor: {
      type: String,
      default: "black",
    },
    okText: {
      type: String,
      default: "OK",
    },
    okColor: {
      type: String,
      default: "primary",
    },
    okIcon: {
      type: String,
    },
    maxWidth: {
      type: String,
      default: "650",
    },
    noHide: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    headerBg: {
      type: String,
      default: "",
    },
    footerBg: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    callback: {
      type: Function,
    },
    isCancelCbk: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      visible: this.value,
      closeMethod: 'default', // 'default', 'callback',
      width: undefined,
      mutLoading: false, // FIXME: replace with 'loading'
      resolve: null,
      persistent: false,
    };
  },

  watch: {
    visible(value) {
      this.$emit("input", value);
    },
  },

  methods: {
    async show(params = {}) {
      this.closeMethod = params.closeMethod ?? 'default';
      this.width = params.width;
      this.persistent = params.isCancelCbk;
      this.visible = true;
      return new Promise((resolve) => this.resolve = resolve);
    },

    hide() {
      this.reset();
      this.visible = false;
    },

    reset() {
      this.mutLoading = false;
    },

    cancel() {
      this.hide();
      this.$emit("cancel");

      if (this.isCancelCbk) {
        if (typeof this.callback === "function") {
          this.callback(false);
        }
      }
    },

    confirm() {
      if (this.resolve) {
        this.resolve();
      }

      if (this.closeMethod === "callback") {
        this.mutLoading = true;
        if (typeof this.callback === "function") {
          this.callback(() => {
            this.hide();
            this.mutLoading = false;
          });
        }
      } else {
        if (this.noHide === false) {
          this.hide();
        }
        this.$emit("confirm");

        if (typeof this.callback === "function") {
          this.callback(true);
        }
      }
    },
  },
};
</script>

<style scoped>
/* remove dialog shadow */
::v-deep .v-dialog {
  box-shadow: none;
}
.v-sheet.v-card {
  box-shadow: none;
}

/* dialog style */
.v-dialog > .v-card {
  border-radius: 16px;
}
.v-dialog > .v-card > .v-card__title {
  font-size: 24px;
  font-weight: 500;

  word-break: break-word;
}
.v-dialog > .v-card > .v-card__text {
  font-size: 16px;
  font-weight: 400;
}
</style>
