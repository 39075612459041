<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="16" fill="#E3E3E3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.5026 23.5026C23.228 23.7773 22.7826 23.7773 22.5079 23.5026L8.49736 9.49208C8.22268 9.21739 8.22268 8.77204 8.49736 8.49736C8.77204 8.22268 9.21739 8.22268 9.49208 8.49736L23.5026 22.5079C23.7773 22.7826 23.7773 23.228 23.5026 23.5026Z" fill="#8C8C8C"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.49736 23.5026C8.77205 23.7773 9.2174 23.7773 9.49208 23.5026L23.5026 9.49208C23.7773 9.21739 23.7773 8.77204 23.5026 8.49736C23.228 8.22268 22.7826 8.22268 22.5079 8.49736L8.49736 22.5079C8.22268 22.7826 8.22268 23.228 8.49736 23.5026Z" fill="#8C8C8C"/>
  </svg>
</template>

<script>
export default {
  name: "CloseIcon"
}
</script>

<style scoped>

</style>