<template>
  <v-app app>
    <v-main style="background: #F6F6F8;">
      <v-container>
        <slot></slot>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "ConfirmLayout"
};
</script>

<style scoped>

</style>