<template>
  <v-app app>

    <RegistrationDrawer  @login="swapToLogin()" :visible.sync="registrationDrawer"></RegistrationDrawer>
    <LoginDrawer @forgot="swapToForgot()" @register="swapToRegister" :visible.sync="loginDrawer"></LoginDrawer>
    <ForgotPasswordDrawer ref="forgot-password" @login="swapToLogin()" :visible.sync="forgotDrawer"></ForgotPasswordDrawer>

    <TopBar @registration="registrationDrawer = !registrationDrawer" @login="loginDrawer = !loginDrawer"></TopBar>

    <v-main>
      <slot></slot>
    </v-main>

    <Alert ref="alert"></Alert>
    <ErrorDialog
      ref="errorDialog"
      :title="dialogs.error.title"
      :text="dialogs.error.text"
      :errors="dialogs.error.errors"
      :ok="dialogs.error.ok"
      :callback="dialogs.error.callback"
    ></ErrorDialog>
    <ConfirmationDialog
      ref="confirmDialog"
      :title="dialogs.confirm.title"
      :text="dialogs.confirm.text"
      :ok-text="dialogs.confirm.okButton"
      :callback="dialogs.confirm.callback"
      :cancel-text="dialogs.confirm.cancelButton"
      isCancelCbk
    ></ConfirmationDialog>
  </v-app>
</template>

<script>
import TopBar from "@/components/TopBar";
import RegistrationDrawer from "@/components/Auth/Registration/RegistrationDrawer"
import LoginDrawer from "@/components/Auth/Authorization/LoginDrawer"
import ForgotPasswordDrawer from "@/components/Auth/ForgotPassword/ForgotPasswordDrawer";
import Alert from "@/components/Alert";
import ErrorDialog from "@/components/ErrorDialog";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import { setCookie } from "@/utils";

export default {

  name: "DefaultLayout",

  components: {
    ErrorDialog,
    ConfirmationDialog,
    // NavBar,
    TopBar,
    RegistrationDrawer,
    LoginDrawer,
    ForgotPasswordDrawer,
    Alert
  },

  beforeMount() {
    // automatically switch to specific drawer
    switch (this.$route.meta.drawer) {
      case 'login':
        this.swapToLogin();
        break;
      case 'register':
        this.registrationDrawer = true;
        break;
    }
    switch (this.$route.params.form) {
      case 'login':
        this.swapToLogin();
        break;
    }
    if (this.$route.params.isResetPassword) {
      this.swapToForgot()
    }

    // set auth token (used for automatic authorization from CMS)
    if (this.$route.query.action === "set_auth" && this.$route.query.token) {
      setCookie('auth', this.$route.query.token);
      window.location.href = '/profile'; // redirect to profile page
    }
  },

  data() {
    return {
      loginDrawer: false,
      registrationDrawer: false,
      forgotDrawer: false,
      dialogs: {
        error: {
          text: undefined,
          title: undefined,
          errors: undefined,
          ok: undefined,
          callback: undefined,
        },
        confirm: {
          text: undefined,
          title: undefined,
          callback: undefined,
          okButton: undefined,
          cancelButton: undefined,
        },
      }
    }
  },

  provide() {
    return {
      alert: this.showAlert,
      showLoginForm: this.swapToLogin,
      showError: this.showError,
      confirm: this.confirm,
    }
  },

  methods: {
    swapToRegister() {
      this.registrationDrawer = true;
      this.loginDrawer = false;
      this.forgotDrawer = false;
    },

    swapToForgot() {
      this.registrationDrawer = false;
      this.loginDrawer = false;
      this.forgotDrawer = true;
      this.$refs['forgot-password'].show();
    },

    swapToLogin() {
      this.registrationDrawer = false;
      this.loginDrawer = true;
      this.forgotDrawer = false;
    },

    showError(title, params = {}) {
      return new Promise((resolve) => {
        this.dialogs.error = {
          title: title,
          text: params.text,
          ok: params.ok,
          errors: params.errors,
          callback: resolve
        };
        this.$refs.errorDialog.show();
      });
    },

    /** @return Promise */
    confirm(title, text = null, ok = "ОК", cancel = "Отмена", params = {}) {
      if (typeof title === "object") {
        params = title;
        title = params.title;
        text = params.text;
        ok = params.ok;
        cancel = params.cancel;
      }

      return new Promise((resolve) => {
        this.dialogs.confirm = {
          title: title,
          text: text,
          okButton: ok,
          cancelButton: cancel,
          callback: resolve
        };
        this.$refs.confirmDialog.show(params);
      });
    },

    showAlert(title, text) {
      this.$refs.alert.show(title, text);
    },
  }
};
</script>

<style scoped>

</style>
