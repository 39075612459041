export function openAmoChat() {
  window.amoSocialButton("runChatShow");
}

export function closeAmoChat() {
  window.amoSocialButton("runChatHide");
}

/**
 * Returns formatted phone value
 *
 * @param {string} phone Raw phone value
 * @return {string}
 */
export function formatPhone(phone) {
  if (!phone) {
    return "";
  }
  const value = phone.replace(/\D/g, ""); // keep only digits

  if (value[0] === "8") {
    value[0] = "7";
  }

  let formatted = "+";
  for (let i = 0; i < value.length; i++) {
    switch (i) {
      case 1:
        formatted += " (";
        break;
      case 4:
        formatted += ") ";
        break;
      case 7:
      case 9:
        formatted += "-";
        break;
    }
    formatted += value[i];
  }

  return formatted;
}

/**
 * flatten object
 */
export function flattenObject(ob) {
  // the object which contains the final result
  const result = {};

  // loop through the object
  for (const i in ob) {
    // we check the type of the i using
    // typeof() function and recursively
    // call the function again
    if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
      const temp = flattenObject(ob[i]);
      for (const j in temp) {
        // store temp in result
        result[i + "." + j] = temp[j];
      }
    } else {
      // else store ob[i] in result directly
      result[i] = ob[i];
    }
  }

  return result;
}

export function groupItemsByDate(items, dateField = "date") {
  const mappedItems = new Map();
  items.forEach((t) => {
    const date = new Date(t[dateField]);
    const formattedDate = formatDate(date);
    if (mappedItems.has(formattedDate)) {
      mappedItems.get(formattedDate).push(t);
    } else {
      mappedItems.set(formattedDate, [t]);
    }
  });

  return Array.from(mappedItems);
}

export function formatAmount(amount, fractionDigits = 2, noCurrency = false) {
  const options = {
    currency: "RUB",
    minimumFractionDigits: fractionDigits,
    // This option is responsible to show number of digits, after amount, example: if 2, it will show 2 000,00 if 0, it will show 2 000
    maximumFractionDigits: fractionDigits,
  };

  if (!noCurrency) {
    options.style = "currency";
  }

  const formatter = new Intl.NumberFormat("ru-RU", options);

  return formatter.format(amount);
}

export function getPlatform() {
  if (navigator.userAgent.indexOf("Win") !== -1) {
    return "Windows";
  } else if (navigator.userAgent.indexOf("Mac") !== -1) {
    return "MacOS";
  } else {
    return "Linux";
  }
}

const isYesterday = (date) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  if (yesterday.toDateString() === date.toDateString()) {
    return true;
  }

  return false;
};

export function isToday(date) {
  const today = new Date();

  if (today.toDateString() === date.toDateString()) {
    return true;
  }

  return false;
}

export function formatDate(date) {
  if (date) {
    const months = [
      "января",
      "февраля",
      "марта",
      "апреля",
      "мая",
      "июня",
      "июля",
      "августа",
      "сентября",
      "октября",
      "ноября",
      "декабря",
    ];
    const weeks = [
      "Воскресенье",
      "Понедельник",
      "Вторник",
      "Среда",
      "Четверг",
      "Пятница",
      "Суббота",
    ];

    const month = months[date.getMonth()];
    const week = weeks[date.getDay()];

    if (isToday(date)) {
      return `Сегодня, ${date.getDate()} ${month}`;
    }

    if (isYesterday(date)) {
      return `Вчера, ${date.getDate()} ${month}`;
    }

    if (date.getFullYear() === new Date().getFullYear()) {
      return `${week}, ${date.getDate()} ${month}`;
    }

    return `${week}, ${date.getDate()} ${month}, ${date.getFullYear()}`;
  }
}

export function formatDateTimeReadable(date) {
  if (date === null || date === undefined) {
    return null;
  }
  if (typeof date === "string") {
    date = new Date(date);
  }

  const DAYS = [
    "Воскресенье",
    "Понедельник",
    "Вторник",
    "Среда",
    "Четверг",
    "Пятница",
    "Суббота",
  ];
  const MONTHS = [
    "Января",
    "Февраля",
    "Марта",
    "Апреля",
    "Мая",
    "Июня",
    "Июля",
    "Августа",
    "Сентября",
    "Октября",
    "Ноября",
    "Декабря",
  ];

  const day_name = DAYS[date.getUTCDay()];
  const dd = date.getDate();
  const month_name = MONTHS[date.getUTCMonth()];
  const yyyy = date.getFullYear();
  const hour = date.getHours().toString().padStart(2, "0");
  const min = date.getMinutes().toString().padStart(2, "0");
  return `${day_name}, ${dd} ${month_name} ${yyyy} в ${hour}:${min}`;
}

export function formatDateStandart(
  date,
  showTime = false,
  isShort = false,
  showHourDiff = false,
  showYear = true
) {
  if (!date) {
    return "";
  }

  const timeOptions = {};

  if (showTime) {
    timeOptions.hour = "numeric";
    timeOptions.minute = "numeric";
  }
  if (showYear) {
    timeOptions.year = "numeric";
  }

  const formatter = new Intl.DateTimeFormat("ru", {
    month: isShort ? "numeric" : "long",
    day: "numeric",
    ...timeOptions,
  });

  if (showHourDiff) {
    const difference = new Date() - new Date(date);
    const hours = Math.floor(difference / 36e5);
    if (hours === 0) {
      const minutes = Math.floor((difference % 36e5) / 60000);
      if (minutes === 0) {
        const seconds = Math.floor((difference % 60000) / 1000);
        if (seconds === 0) {
          return "Только что";
        }
        return `${seconds} ${pluralize(seconds, [
          "секунду",
          "секунды",
          "секунд",
        ])} назад`;
      }
      return `${minutes} ${pluralize(minutes, [
        "минуту",
        "минуты",
        "минут",
      ])} назад`;
    }
    if (hours < 24 && hours > 0) {
      return `${hours} ${pluralize(hours, ["час", "часа", "часов"])} назад`;
    }
  }

  return formatter.format(new Date(date));
}

export function compareDates(date1_from, date1_to, date2_from, date2_to) {
  // Convert everything to the Date object
  if (typeof date1_from === "string") {
    date1_from = new Date(date1_from);
  }
  if (typeof date1_to === "string") {
    date1_to = new Date(date1_to);
  }
  if (typeof date2_from === "string") {
    date2_from = new Date(date2_from);
  }
  if (typeof date2_to === "string") {
    date2_to = new Date(date2_to);
  }

  // Compare dates (without time)
  return (
    date1_from.toDateString() !== date2_from.toDateString() ||
    date1_to.toDateString() !== date2_to.toDateString()
  );
}

export function formatVuetifyDatepickerDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function debounce(method, delay = 1000) {
  clearTimeout(method._tId);
  method._tId = setTimeout(function () {
    method();
  }, delay);
}

export function debounceWatch(fn, wait) {
  let timer;
  return function (...args) {
    if (timer) {
      clearTimeout(timer); // clear any pre-existing timer
    }
    timer = setTimeout(() => {
      fn.apply(this, args); // call the function if time expires
    }, wait);
  };
}

/**
 * @param {count} count
 * @param {string[]} words Array of words. Example: ['уведомление', 'уведомления', 'уведомлений'], ['коментарий', 'коментария', 'комментариев']
 * @return {string}
 */
export function pluralize(count, words) {
  const cases = [2, 0, 1, 1, 1, 2];
  return words[
    count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)]
  ];
}

/**
 * @param key {string}
 * @param value {string}
 * @param expires {Date}
 */
export function setCookie(key, value, expires) {
  let cookie = `${key}=${value}`;
  if (expires) {
    cookie += `;expires=${expires.toUTCString()}`;
  }
  cookie += `;path=/`;

  document.cookie = cookie;
}

export function getCookie(key) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${key}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export function hasCookie(key) {
  return getCookie(key) !== undefined;
}

export function deleteCookie(key) {
  setCookie(key, "", new Date());
}

export function getAddressText(address, short) {
  if (address) {
    let parts;
    if (short) {
      parts = [address.city, address.street, address.house, address.flat];
    } else {
      parts = [
        address.postal,
        address.region,
        address.city,
        address.street,
        address.house,
        address.block,
        address.floor ? `этаж ${address.floor}` : null,
        address.flat,
      ];
    }

    return parts.filter(Boolean).join(", ");
  }
}

export function getLotAddressText(lot) {
  const address_parts = [
    lot.delivery_address_postal,
    lot.delivery_address_region,
    lot.delivery_address_city,
    lot.delivery_address_street,
    lot.delivery_address_house,
    lot.delivery_address_block,
    lot.delivery_address_floor ? `этаж ${lot.delivery_address_floor}` : null,
    lot.delivery_address_flat,
  ];
  return address_parts.filter(Boolean).join(", ");
}

export function getDatesColor() {
  // TODO
}

/**
 * Returns true or false depending on whether the user is browsing with a mobile device
 *
 * @return {boolean}
 */
export function isMobileDevice() {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  if (isMobile) {
    return true;
  }

  return false;
}

/**
 * Download file in browser from URL
 *
 * @param {string} url Download file URL
 * @param {string} name The name of the file download
 */
export function downloadFile(url, name) {
  const link = document.createElement("a");
  link.download = name;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function coverntFiletoBlobAndDownload(file, name) {
  const blob = await fetch(file).then((r) => r.blob());
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = name; // add custom extension here
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  // Remove "a" tag from the body
  a.remove();
}

/**
 * Truncate text to a specified length
 *
 * @param {string} string a string to be truncated
 * @param {number} limit characters limit
 * @param {string} end overflow characters
 * @return {string}
 */
export function truncateString(string, limit, end = "...") {
  if (string.length > limit) {
    return string.substring(0, limit - end.length) + end;
  } else {
    return string;
  }
}

export function scrollToTop(topCoords = 0, isSmooth = true) {
  window.scrollTo({
    top: topCoords,
    behavior: isSmooth ? "smooth" : "auto",
  });
}

export function isCustomProduct(product) {
  if (!product) {
    return false;
  }
  return isCustomProductID(product.id);
}
export function isCustomProductID(productID) {
  if (productID === undefined) {
    return false;
  }
  if (productID.toString().length === 8) {
    return false; // real product ID should be 8 characters long
  }
  return true;
}

export function generateString(length, allowedCharacters) {
  let result = "";
  for (let i = 0; i < length; i++) {
    result +=
      allowedCharacters[Math.floor(Math.random() * allowedCharacters.length)];
  }
  return result;
}

export function isBP2Product(productId) {
  return productId?.toString().length !== 8;
}

export function formatFullName(name, last_name, middle_name) {
  return [last_name, name, middle_name].join(" ").trimEnd(); // ФИО
}
