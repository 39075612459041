import { render, staticRenderFns } from "./InstructionModal.vue?vue&type=template&id=8c3efeb0&scoped=true&"
import script from "./InstructionModal.vue?vue&type=script&lang=js&"
export * from "./InstructionModal.vue?vue&type=script&lang=js&"
import style0 from "./InstructionModal.vue?vue&type=style&index=0&id=8c3efeb0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c3efeb0",
  null
  
)

export default component.exports