<template>
  <div style="padding: 40px;">
    <navigation-drawer-header @close="$emit('close')"/>

    <v-form @submit.prevent="setPassword">
      <h1 class="text-center mt-3">Придумайте пароль</h1>
      <p class="mt-5">Для защиты ваших данных необходимо придумать<br>безопасный пароль. </p>

      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <ValidationProvider
            :rules="{ required: true, max: 18 }"
            v-slot="{ errors }"
            name="новый пароль"
        >
          <v-text-field
              v-model="password"
              color="primary"
              outlined
              rounded
              dense
              required
              class="mb-0"
              style="margin-top: 270px;"
              placeholder="Придумайте пароль"
              label="Придумайте пароль"
              :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (passwordVisible = !passwordVisible)"
              :type="passwordVisible ? 'password' : 'text'"
              hide-details="auto"
              :error-messages="errors"
          ></v-text-field>
        </ValidationProvider>
        <div class="text-left mt-4">
          <p class="subtitle mt-0">Он должен содержать:</p>
          <p class="mb-1"><PasswordCircle :fill="passwordsValidations.length ? '#7CC156' : undefined" class="mr-2"></PasswordCircle>6 и более символов</p>
          <p class="mb-1"><PasswordCircle :fill="passwordsValidations.uppers ? '#7CC156' : undefined" class="mr-2"></PasswordCircle>заглавные латинские буквы</p>
          <p class="mb-1"><PasswordCircle :fill="passwordsValidations.lowers ? '#7CC156' : undefined" class="mr-2"></PasswordCircle>строчные латинские буквы</p>
          <p class="mb-1"><PasswordCircle :fill="passwordsValidations.numbers ? '#7CC156' : undefined" class="mr-2"></PasswordCircle>цифры</p>
        </div>
        <v-btn
            color="primary"
            elevation="0"
            class="mt-5"
            block
            large
            height="48px"
            :disabled="invalid || !passwordIsValid"
            :loading="loading"
            type="submit"
        >
          Продолжить
        </v-btn>
      </ValidationObserver>
    </v-form>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import PasswordCircle from '@/components/Icons/PasswordCircle'
import {PASSWORD_LOWER_WORDS_REGEXP, PASSWORD_UPPER_WORDS_REGEXP, PASSWORD_NUMBERS_REGEXP} from "@/const";
import NavigationDrawerHeader from "@/components/Auth/ForgotPassword/NavigationDrawerHeader";

export default {

  name: "CreatePasswordStep",

  props: [ 'loading' ],

  components: {
    NavigationDrawerHeader,
    ValidationProvider,
    ValidationObserver,
    PasswordCircle
  },

  computed: {
    passwordsValidations() {
      const password = this.password ?? '';

      return {
        length: password.length >= 6 && password.length <= 18,
        uppers: PASSWORD_UPPER_WORDS_REGEXP.test(password),
        lowers: PASSWORD_LOWER_WORDS_REGEXP.test(password),
        numbers: PASSWORD_NUMBERS_REGEXP.test(password),
      }
    },
    passwordIsValid() {
      return Object.values(this.passwordsValidations).every(Boolean)
    }
  },
  data: () => {
    return {
      password: "",
      passwordVisible: true
    }
  },
  methods: {
    async setPassword() {
      const status = await this.$refs.observer.validate();
      if (status) {
        this.$emit('done', this.password);
      }
    }
  }
}
</script>

<style scoped>
.item-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #000000;
}

</style>