import BackendError from "@/models/BackendError";

export default class BackendValidationError extends BackendError {

    constructor(fields) {
        const [ firstFieldName, firstFieldMessage ] = Object.entries(fields)[0];
        super(firstFieldMessage);
        this.name = "BackendValidationError";
        this.field = firstFieldName;
        this.fields = fields;
    }
}