<template>
  <v-navigation-drawer
      v-model="opened"
      fixed
      temporary
      :permanent="permanent"
      width="595"
      right
      class="text-center"
      height="100%"
  >
    <transition name="slide">

      <RegistrationStep
          key="registration"
          v-if="registrationSheet === 1"
          v-model="registrationData"
          :loading="loaders.verificationRequest"
          @submit="onRegistrationStepDone"
          @login="$emit('login')"
          @close="opened = false"
      ></RegistrationStep>

      <PhoneVerificationStep
          key="verification"
          :verificationToken="smsVerificationToken"
          v-else-if="registrationSheet === 2"
          @done="onPhoneVerificationStepDone"
          @changeVerificationToken="changeVerificationToken"
          :phone="registrationData.phone"
          @changePhone="registrationSheet = 1"
          @close="opened = false"
      ></PhoneVerificationStep>

      <CreatePasswordStep
          key="password"
          v-else-if="registrationSheet === 3"
          :loading="loaders.registration"
          @done="onCreatePasswordDone"
          @close="opened = false"
      ></CreatePasswordStep>

      <ChooseRoleStep
          key="role"
          v-else-if="registrationSheet === 4"
          :loading="loaders.registration"
          @done="onChooseRoleDone"
          @close="opened = false"
          @permanent:show="(isPermanent) => permanent = isPermanent"
      ></ChooseRoleStep>

      <SuccessStep
          key="success"
          v-else-if="registrationSheet === 5"
          :role="registrationData.role"
          :registrationData="registrationData"
          @done="registrationSheet++"
          @close="opened = false"
      ></SuccessStep>

    </transition>
  </v-navigation-drawer>

</template>

<script>
import {
  RegistrationStep,
  PhoneVerificationStep,
  CreatePasswordStep,
  ChooseRoleStep,
  SuccessStep
} from "@/components/Auth/Registration/Steps"

import { requestSMSCode, register } from "@/services/api";
import { setCookie } from "@/utils";

export default {

  props: ['visible'],

  name: "RegistrationMain",

  components: {
    RegistrationStep,
    PhoneVerificationStep,
    CreatePasswordStep,
    ChooseRoleStep,
    SuccessStep
  },

  data() {
    return {
      loaders: {
        verificationRequest: false,
        registration: false
      },
      registrationData: {},
      registrationSheet: 1,
      smsVerificationToken: "",
      loginData: null,
      permanent: false,
    }
  },

  methods: {

    async onRegistrationStepDone() {
      this.loaders.verificationRequest = true;

      requestSMSCode(this.registrationData.phone)
          .then(token => {
            this.smsVerificationToken = token;
            this.registrationSheet = 2; // proceed to 'phone verification' step
          })
          .catch(error => {
            this.$handleServerError(error);
          })
          .finally(() => {
            this.loaders.verificationRequest = false;
          });
    },

    async onPhoneVerificationStepDone(token) {
      this.registrationData.token = token; // set 'register' action verification token
      this.registrationSheet = 3; // proceed to 'create password' step
    },

    async onCreatePasswordDone(password) {
      this.registrationData.password = password;

      if (this.$route.query.invite) { // has employee invite
        this.register();
      } else {
        this.registrationSheet = 4; // proceed to 'choose role' step
      }
    },

    onChooseRoleDone(role) {
      this.registrationData.role = role;
      this.register();
    },

    changeVerificationToken(token) {
      this.smsVerificationToken = token;
    },

    register() {
      const registerPayload = {
        name: this.registrationData.name,
        last_name: this.registrationData.lastName,
        occupation: this.registrationData.occupation,
        phone: this.registrationData.phone,
        email: this.registrationData.email,
        password: this.registrationData.password,
        role: this.registrationData.role,
        is_terms_of_service_accepted: this.registrationData.terms,
        is_advertising_agreement_accepted: this.registrationData.advertising,
        invite: this.registrationData.invite,
        token: this.registrationData.token
      };
      if (this.registrationData.middleName !== "") {
        registerPayload.middle_name = this.registrationData.middleName;
      }

      this.loaders.registration = true;

      register(registerPayload)
          .then(authToken => {
            setCookie('auth', authToken);
            this.$store.dispatch('fetchAccount');
            this.registrationSheet = 5; // proceed to 'success' step
          })
          .catch(error => {
            this.$handleServerError(error);
          })
          .finally(() => {
            this.loaders.registration = false;
          });
    }
  },

  computed: {
    opened: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('update:visible', value)
      }
    }
  },

  beforeMount() {
    if (this.$route.query.token && this.$route.query.code) {
      this.$store.dispatch('setVerificationToken', this.$route.query.token)
      this.opened = true
      this.proceedToEmailVerification(this.$route.query.token)
      this.verificationCode = this.$route.query.code
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 39px;
}

.agreement {
  font-size: 14px;
  line-height: 130%;
}
</style>
