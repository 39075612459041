<template>
  <v-navigation-drawer
      v-model="opened"
      fixed
      temporary
      :permanent="permanent"
      width="595"
      right
      style="padding: 40px; text-align: center;"
  >
    <ForgotPasswordStep
        v-if="sheet === 1"
        @close="opened = false"
        @done="proceedToOTP"
        @permanent:show="(isPermanent) => permanent = isPermanent"
    ></ForgotPasswordStep>

    <PhoneVerificationStep
        :phone="verificationPhone"
        :token="verificationToken"
        v-else-if="sheet === 2"
        @close="opened = false"
        @done="proceedToCreatePassword"
        @changePhone="sheet = 1"
        @login="login"
    ></PhoneVerificationStep>

    <CreatePasswordStep
        :phone="verificationPhone"
        :token="verificationToken"
        v-else-if="sheet === 3"
        @close="opened = false"
        @done="sheet = 4"
    ></CreatePasswordStep>

    <SuccessStep
        v-else-if="sheet === 4"
        @close="opened = false"
        @done="login()"
    ></SuccessStep>

  </v-navigation-drawer>
</template>

<script>

import {
  ForgotPasswordStep,
  PhoneVerificationStep,
  CreatePasswordStep,
  SuccessStep
} from "@/components/Auth/ForgotPassword/Steps"

export default {

  props: [ 'visible' ],

  name: "ForgotPasswordDrawer",

  components: {
    CreatePasswordStep,
    ForgotPasswordStep,
    PhoneVerificationStep,
    SuccessStep
  },

  data() {
    return {
      sheet: 1,
      verificationPhone: "",
      verificationToken: "",
      permanent: false,
    }
  },

  methods: {

    proceedToOTP({ phone, token }) {
      this.verificationPhone = phone;
      this.verificationToken = token;
      this.sheet = 2;
    },

    proceedToCreatePassword({ token }) {
      this.verificationToken = token;
      this.sheet = 3;
    },

    login() {
      this.sheet = 1
      this.$emit('login')
    },

    show() {
      this.sheet = 1;
    }
  },

  computed: {
    opened: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('update:visible', value)
      }
    }
  }
}
</script>

<style lang="scss">
h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 39px;
}

.agreement {
  font-size: 14px;
  line-height: 130%;
}

.drawer-footer {

}

.link {
  border-bottom: 1px dashed #1B7DC9; /* TODO: УБРАТЬ ЭТО*/
  color: #1B7DC9;
}

</style>