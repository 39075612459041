<template>
  <div style="padding: 40px;">
    <navigation-drawer-header @close="$emit('close')"/>
    <div>
      <h1 class="text-center mt-3">Вы успешно зарегистрированы в системе как {{ role === 'customer' ? 'покупатель' : 'поставщик' }}</h1>
      <p class="mt-5">Вам необходимо внести данные организации для участия в тендерных процедурах</p>
      <v-btn
          color="primary"
          elevation="0"
          block
          large
          height="48px"
          link
          :loading="submitting"
          @click="loginAndNavigateTo('/organizations/create?ft=y')"
          class="mt-10"
      >Заполнить данные организации</v-btn>
      <v-btn
          color="primary"
          elevation="0"
          block
          large
          outlined
          height="48px"
          link
          @click="loginAndNavigateTo('/procedures')"
          class="mt-5"
      >Перейти в личный кабинет</v-btn>
    </div>
  </div>
</template>


<script>

import NavigationDrawerHeader from "@/components/Auth/ForgotPassword/NavigationDrawerHeader";
import { login } from "@/services/api";
import { setCookie } from '@/utils';

export default {

  name: "SuccessStep",

  components: { NavigationDrawerHeader },

  props: [ 'role', 'registrationData' ],

  data() {
    return {
      submitting: false
    }
  },
  
  methods: {
    loginAndNavigateTo(path) {

      this.submitting = true;

      login(this.registrationData.phone, this.registrationData.password)
          .then((token) => {
            setCookie('auth', token);
            window.location.href = path;
          })
          .finally(() => {
            this.submitting = false;
          })
    },
  }
}
</script>

<style scoped>

</style>