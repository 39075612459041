<template>
  <div style="display: flex; flex-direction: column; height: 100%;">
    <navigation-drawer-header @close="$emit('close')"/>
    <v-spacer/>
    <h1 class="text-center">Восстановление пароля</h1>
    <v-spacer/>
    <div>
      <h2 class="font-weight-medium" style="color: #474747; font-size: 30px; margin-bottom: 20px;">Ввод кода</h2>
      <div style="color: #6B6B6B; margin-bottom: 20px;">Для защиты Вашего профиля мы отправили на указанный номер телефона бесплатное сообщение с кодом.</div>
      <div style="font-size: 20px; margin-bottom: 20px">{{formatPhone(phone)}}</div>
      <p class="mt-0 mb-5" style="color: #1B7DC9;font-size: 14px; font-weight: 500; cursor: pointer" @click="$emit('changePhone')">Изменить номер телефона</p>
    </div>
    <div>
      <validation-observer
          ref="observer"
          v-slot="{ invalid }"
      >
        <validation-provider
            v-slot="{ errors }"
            name="code"
        >
          <v-otp-input
              length="4"
              height="100px"
              color="primary"
              @finish="checkCode()"
              :class="{ 'otp-error': errors.length > 0 || error }"
              v-model="code"
              style="margin-bottom: 10px;"
          ></v-otp-input>
          <p v-if="error" class="red--text" v-text="error" />
          <div :style="{ visibility: errors.length > 0 ? 'visible' : 'hidden' }" style="color: #D82727; margin-bottom: 20px; font-size: 12px; font-weight: 500;">{{ errors[0] || '&nbsp;' }}</div>
        </validation-provider>
        <v-btn
            color="primary"
            elevation="0"
            block
            large
            height="48px"
            style="margin-bottom: 20px;"
            :disabled="invalid || loading || !!error"
            :loading="loading"
            @click="checkCode()"
        >Продолжить</v-btn>
      </validation-observer>
      <div style="color: #9AA6B0; height: 50px;">
        <template v-if="smsTimeout > 0">Код отправлен, повторная отправка<br>возможна через: <span style="color: #424A54;">{{ formatTimeout(smsTimeout) }}</span></template>
        <template v-else>Не пришёл код? <a @click.prevent="resendCode()" class="link" :class="{ disabled: loading }" style="font-size: 14px;">Отправить повторно</a></template>
      </div>
    </div>
    <v-spacer/>
    <v-divider style="margin-bottom: 30px;"></v-divider>
    <div>
      <div style="margin-bottom: 10px;">Вспомнили пароль? <span style="font-size: 14px; cursor: pointer;" class="link" @click="$emit('login')">Войти</span></div>
      <div>Возникли вопросы? <span style="font-size: 14px;" class="link" @click.prevent="openAmoChat">Написать в техническую поддержку</span></div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import NavigationDrawerHeader from "@/components/Auth/ForgotPassword/NavigationDrawerHeader";
import { requestPasswordReset, verifyOTPCode } from "@/services/api";
import { formatPhone, openAmoChat } from "@/utils";

export default {

  components: { NavigationDrawerHeader, ValidationObserver, ValidationProvider },

  props: [ 'token', 'phone' ],

  name: "PhoneVerificationStep",

  data() {
    return {
      loading: false,
      code: "",
      interval: null,
      smsTimeout: 0,
      mutableToken: this.token,
      error: "",
    }
  },

  beforeMount() {
    window.setInterval(() => this.updateTimeout(), 1000); // run every second
  },

  beforeDestroy() {
    window.clearInterval(this.interval);
  },

  methods: {
    openAmoChat,

    formatPhone,

    resendCode() {
      this.loading = true;

      requestPasswordReset({ phone: this.phone })
          .then((result) => {
            this.mutableToken = result.token;
            this.$store.state.smsSentAt = Date.now();
            this.code = "";
            this.updateTimeout();
          })
          .catch((error) => {
            this.$handleServerError(error);
          })
          .finally(() => {
            this.loading = false;
          });
    },

    updateTimeout() {
      const smsSentAt = this.$store.state.smsSentAt ?? Date.now();
      this.smsTimeout = 60 - Math.floor((Date.now() - smsSentAt) / 1000);
    },

    formatTimeout(time) {
      const minutes = Math.floor(time / 60).toString().padStart(2, '0');
      const seconds = Math.floor(time % 60).toString().padStart(2, '0');
      return `${minutes}:${seconds}`;
    },

    async checkCode() {
      this.loading = true;

      try {
        const token = await verifyOTPCode(this.code, this.mutableToken);
        this.$emit('done', { token: token });
        this.error = "";
      } catch (error) {
        // this.$handleServerError(error, this.$refs.observer);
        this.error = error.message;
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
  .v-otp-input::v-deep .v-input {
    font-size: 70px;
    border-radius: 16px;
    max-width: 90px;
    margin: 0 15px;

  }

  .v-otp-input::v-deep input {
    max-height: none;
  }


  .v-otp-input::v-deep .v-input input {
    color: var(--v-primary-base)!important;
    line-height:0.5;
  }

  /*.otp-error {*/
  /*  border-color: #FF4848!important;*/
  /*}*/


  .otp-error::v-deep .v-input input {
    color: #FF4848!important;
  }

  .otp-error::v-deep .v-input fieldset {
    border-color: #FF4848;
  }

  .link {
    font-weight: 500;
  }
  .link.disabled {
    pointer-events: none;
    color: #9AA6B0;
  }
</style>
