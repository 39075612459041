<template>
  <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.05673 0H0.525131C0.0875263 0 0 0.208143 0 0.437596C0 0.847472 0.519251 2.88023 2.41771 5.56871C3.68335 7.40473 5.46652 8.4 7.08916 8.4C8.06273 8.4 8.18317 8.17895 8.18317 7.79821V6.41053C8.18317 5.96842 8.2754 5.88018 8.58366 5.88018C8.81085 5.88018 9.20027 5.99495 10.109 6.88017C11.1474 7.92933 11.3186 8.4 11.9027 8.4H13.4343C13.872 8.4 14.0908 8.17895 13.9646 7.74272C13.8264 7.30796 13.3306 6.67711 12.6727 5.92938C12.3157 5.50315 11.7802 5.04416 11.618 4.81461C11.3908 4.51955 11.4557 4.38838 11.618 4.12612C11.618 4.12612 13.484 1.4704 13.6787 0.568813C13.7761 0.240903 13.6787 0 13.2156 0H11.684C11.2945 0 11.115 0.208143 11.0176 0.437596C11.0176 0.437596 10.2388 2.35562 9.13539 3.60152C8.77839 3.96215 8.61612 4.07692 8.42139 4.07692C8.32405 4.07692 8.18313 3.96215 8.18313 3.63433V0.568813C8.18313 0.175339 8.07012 0 7.74554 0H5.33872C5.09538 0 4.949 0.182589 4.949 0.355673C4.949 0.728633 5.50068 0.814667 5.55756 1.86383V4.14253C5.55756 4.64211 5.46822 4.73269 5.27353 4.73269C4.75431 4.73269 3.49129 2.80609 2.7422 0.601573C2.59542 0.173084 2.44816 0 2.05673 0Z" fill="#8C8C8C"/>
  </svg>
</template>

<script>
export default {
  name: "VkIcon"
}
</script>

<style scoped>

</style>