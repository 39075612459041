import { scrollToTop, hasCookie } from "@/utils";

import Vue from "vue";
import VueRouter from "vue-router";

import { BREADCRUMBS } from "./breadcrumbs";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/HomeView.vue"),
      meta: {
        layout: "DefaultLayout",
      },
      props: true,
    },
    {
      path: "/products/:id",
      name: "product",
      component: () => import("@/views/ProductView.vue"),
      meta: {
        layout: "ProductLayout",
      },
      props: true,
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/HomeView.vue"),
      meta: {
        layout: "DefaultLayout",
        drawer: "login",
        title: "Вход",
      },
      props: true,
    },
    {
      path: "/register",
      name: "register",
      component: () => import("@/views/HomeView.vue"),
      meta: {
        layout: "DefaultLayout",
        drawer: "register",
        title: "Регистрация",
      },
      props: true,
    },
    {
      path: "/invite/:invite",
      name: "register.invite",
      component: () => import("@/views/HomeView.vue"),
      meta: {
        layout: "DefaultLayout",
        drawer: "register",
        title: "Регистрация",
      },
    },
    {
      path: "/procedures",
      name: "procedures",
      component: () => import("@/views/LK/Procedures/ProcedureListView.vue"),
      meta: {
        layout: "LKLayout",
        title: "Закупки",
        breadcrumbs: BREADCRUMBS.PROCEDURES,
        requiresAuth: true,
      },
    },
    {
      path: "/procedures/my",
      name: "procedures.my",
      component: () => import("@/views/LK/Procedures/ProcedureListView.vue"),
      meta: {
        layout: "LKLayout",
        title: "Мои закупки",
        breadcrumbs: BREADCRUMBS.PROCEDURES,
        requiresAuth: true,
      },
      alias: "/procedures",
      props: { tab: "my_procedures" },
    },
    {
      path: "/procedures/all",
      name: "procedures.all",
      component: () => import("@/views/LK/Procedures/ProcedureListView.vue"),
      meta: {
        layout: "LKLayout",
        title: "Все закупки",
        breadcrumbs: BREADCRUMBS.PROCEDURES,
        requiresAuth: true,
      },
      alias: "/procedures",
      props: { tab: "procedures" },
    },
    {
      path: "/procedures/questions",
      name: "procedures.questions.list",
      component: () => import("@/views/LK/Procedures/ProcedureListView.vue"),
      meta: {
        layout: "LKLayout",
        title: "Разъяснения",
        breadcrumbs: BREADCRUMBS.PROCEDURES,
        requiresAuth: true,
      },
      alias: "/procedures",
      props: { tab: "questions" },
    },
    {
      path: "/procedures/create",
      name: "procedures.create",
      component: () => import("@/views/LK/Procedures/CreateProcedureView.vue"),
      meta: {
        layout: "LKLayout",
        title: "Создание закупки",
        breadcrumbs: BREADCRUMBS.PROCEDURES_CREATE,
        requiresAuth: true,
      },
    },
    {
      path: "/procedures/:id/edit",
      name: "procedures.edit",
      component: () => import("@/views/LK/Procedures/CreateProcedureView.vue"),
      meta: {
        layout: "LKLayout",
        title: "Редактирование закупки",
        breadcrumbs: BREADCRUMBS.PROCEDURES_EDIT,
        requiresAuth: true,
      },
      alias: "/procedures",
    },
    {
      path: "/procedures/:procedureId/offers",
      name: "procedures.offers",
      component: () => import("@/views/LK/Procedures/ProcedureOffersView.vue"),
      meta: {
        layout: "LKLayout",
        title: "Выбор лучших предложений",
        breadcrumbs: BREADCRUMBS.PROCEDURES_OFFERS,
        requiresAuth: true,
      },
      alias: "/procedures",
      props: true,
    },
    {
      path: "/procedures/:procedureId/questions/:questionNumber",
      name: "procedures.questions",
      component: () => import("@/views/LK/Procedures/ProcedureQuestionView.vue"),
      meta: {
        layout: "LKLayout",
        title: "Ответ на запрос",
        breadcrumbs: BREADCRUMBS.PROCEDURES_QUESTION,
        requiresAuth: true,
      },
      alias: "/procedures",
      props: true,
    },
    {
      path: "/procedures/:id",
      name: "procedures.view",
      component: () => import("@/views/LK/Procedures/ProcedureView.vue"),
      meta: {
        layout: "LKLayout",
        title: "Закупка",
        breadcrumbs: BREADCRUMBS.PROCEDURES_VIEW,
        requiresAuth: true,
      },
      alias: "/procedures",
      props: true,
    },
    {
      path: "/finance",
      name: "finance",
      component: () => import("@/views/LK/Finance/FinanceView.vue"),
      meta: {
        layout: "LKLayout",
        title: "Финансы",
        breadcrumbs: BREADCRUMBS.FINANCE,
        requiresAuth: true,
      },
    },
    {
      path: "/finance/:inn/wallet",
      name: "finance-wallet",
      component: () => import("@/views/LK/Finance/Wallet/WalletView.vue"),
      meta: {
        layout: "LKLayout",
        title: "Кошелёк",
        breadcrumbs: BREADCRUMBS.FINANCE_WALLET,
        requiresAuth: true,
      },
      alias: "/finance",
    },
    {
      path: "/finance/:inn/transactions",
      name: "finance-transactions",
      component: () => import("@/views/LK/Finance/TransactionHistory/TransactionHistoryView.vue"),
      meta: {
        layout: "LKLayout",
        title: "История операций",
        breadcrumbs: BREADCRUMBS.FINANCE_TRANSACTIONS,
        requiresAuth: true,
      },
      alias: "/finance",
      props: { tab: "payments" },
    },
    {
      path: "/finance/:inn/transactions/tarifs-history",
      name: "finance-tarifs-history",
      component: () => import("@/views/LK/Finance/TransactionHistory/TransactionHistoryView.vue"),
      meta: {
        layout: "LKLayout",
        title: "История Тарифов",
        breadcrumbs: BREADCRUMBS.FINANCE_TARIFS_HISTORY,
        requiresAuth: true,
      },
      alias: "/finance/:inn/transactions",
      props: { tab: "tarifs-history" },
    },
    {
      path: "/finance/:inn/services",
      name: "finance-services",
      component: () => import("@/views/LK/Finance/Services/ServicesView.vue"),
      meta: {
        layout: "LKLayout",
        title: "Тарифы и услуги",
        breadcrumbs: BREADCRUMBS.FINANCE_SERVICES,
        requiresAuth: true,
      },
      alias: "/finance",
    },
    {
      path: "/finance/:inn/services/activate/:payForInn/:planKey",
      name: "finance-services-activate",
      component: () => import("@/views/LK/Finance/Services/ServicesActivateView.vue"),
      meta: {
        layout: "LKLayout",
        title: "Активация тарифа",
        breadcrumbs: BREADCRUMBS.FINANCE_SERVICES_ACTIVATE,
        requiresAuth: true,
      },
      alias: "/finance",
    },
    {
      path: "/finance/:inn/tarifs",
      name: "finance-tarifs",
      component: () => import("@/views/LK/Finance/Tarifs/TarifsView.vue"),
      meta: {
        layout: "LKLayout",
        title: "Мой Тариф",
        breadcrumbs: BREADCRUMBS.FINANCE_TARIFS,
        requiresAuth: true,
      },
      alias: "/finance",
    },
    {
      path: "/finance/:inn/bonuses",
      name: "finance-bonuses",
      component: () => import("@/views/LK/Finance/Bonuses/BonusesView.vue"),
      meta: {
        layout: "LKLayout",
        title: "Бонусы",
        breadcrumbs: BREADCRUMBS.FINANCE_BONUSES,
        requiresAuth: true,
      },
      alias: "/finance",
    },
    {
      path: "/organizations",
      name: "organizations",
      component: () => import("@/views/LK/Organizations/ListOrganizations.vue"),
      meta: {
        layout: "LKLayout",
        title: "Мои организации",
        breadcrumbs: BREADCRUMBS.ORGANIZATIONS,
        requiresAuth: true,
      },
      props: { tab: "default" },
    },
    {
      path: "/organizations/groups",
      name: "organizations_groups",
      component: () => import("@/views/LK/Organizations/ListOrganizations.vue"),
      meta: {
        layout: "LKLayout",
        title: "Группы компаний",
        breadcrumbs: BREADCRUMBS.ORGANIZATIONS_GROUPS,
        requiresAuth: true,
      },
      alias: "/organizations",
      props: { tab: "groups" },
    },
    {
      path: "/organizations/groups/:id",
      name: "organization_group_profile",
      component: () => import("@/views/LK/Organizations/OrganizationGroupProfile.vue"),
      meta: {
        layout: "LKLayout",
        title: "Данные группы компаний",
        breadcrumbs: BREADCRUMBS.ORGANIZATIONS_GROUPS_EDIT,
        requiresAuth: true,
      },
      alias: "/organizations",
    },
    {
      path: "/organizations/edit/:inn",
      name: "organizations.edit",
      component: () => import("@/views/LK/Organizations/EditOrganization.vue"),
      meta: {
        layout: "LKLayout",
        title: "Редактирование организации",

        breadcrumbs: BREADCRUMBS.ORGANIZATIONS_EDIT,
        requiresAuth: true,
      },
      props: true,
      alias: "/organizations",
    },
    {
      path: "/organizations/create",
      name: "organizations.create",
      component: () => import("@/views/LK/Organizations/CreateOrganization.vue"),
      meta: {
        layout: "LKLayout",
        title: "Создание организации",
        breadcrumbs: BREADCRUMBS.ORGANIZATIONS_CREATE,
        requiresAuth: true,
      },
    },
    {
      path: "/journal",
      name: "journal",
      component: () => import("@/views/LK/JournalView.vue"),
      meta: {
        layout: "LKLayout",
        title: "Журнал",
        breadcrumbs: BREADCRUMBS.JOURNAL,
        requiresAuth: true,
      },
    },
    {
      path: "/catalog",
      name: "catalog",
      component: () => import("@/views/LK/Catalog/CatalogView.vue"),
      meta: {
        layout: "LKLayout",
        title: "Каталог",
        breadcrumbs: BREADCRUMBS.CATALOG,
        requiresAuth: true,
      },
    },
    {
      path: "/catalog/:id",
      name: "catalog.category",
      component: () => import("@/views/LK/Catalog/CatalogView.vue"),
      meta: {
        layout: "LKLayout",
        title: "Каталог",
        breadcrumbs: BREADCRUMBS.CATALOG,
        requiresAuth: true,
      },
      alias: "/catalog",
    },
    {
      path: "/catalog/:id/search",
      name: "catalog.search",
      component: () => import("@/views/LK/Catalog/CatalogView.vue"),
      meta: {
        layout: "LKLayout",
        title: "Поиск",
        breadcrumbs: BREADCRUMBS.CATALOG,
        requiresAuth: true,
      },
      alias: "/catalog",
    },
    {
      path: "/catalog/products/:itemId",
      name: "catalog.item",
      component: () => import("@/components/LK/Catalog/CatalogItem.vue"),
      meta: {
        layout: "LKLayout",
        title: "Товар",
        breadcrumbs: BREADCRUMBS.CATALOG_PRODUCT,
        requiresAuth: true,
      },
      props: true,
      alias: "/catalog",
    },
    {
      path: "/counterparties",
      name: "counterparties",
      component: () => import("@/views/LK/CounterpartiesView.vue"),
      meta: {
        layout: "LKLayout",
        title: "Контрагенты",
        breadcrumb: "Контрагенты",
        requiresAuth: true,
      },
    },
    {
      path: "/analytics",
      name: "analytics",
      component: () => import("@/views/LK/AnalyticsView.vue"),
      meta: {
        layout: "LKLayout",
        title: "Аналитика",
        breadcrumbs: BREADCRUMBS.ANALYTICS,
        requiresAuth: true,
      },
    },
    {
      path: "/documents",
      name: "documents",
      component: () => import("@/views/LK/DocumentsView.vue"),
      meta: {
        layout: "LKLayout",
        title: "Документы",
        breadcrumbs: BREADCRUMBS.DOCUMENTS,
        requiresAuth: true,
      },
    },
    {
      path: "/accreditation",
      name: "accreditation",
      component: () => import("@/views/LK/AccreditationView.vue"),
      meta: {
        layout: "LKLayout",
        title: "Аккредитация",
        breadcrumbs: BREADCRUMBS.ACCREDITATION,
        requiresAuth: true,
      },
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("@/views/LK/ProfileView.vue"),
      meta: {
        layout: "LKLayout",
        title: "Личные данные",
        breadcrumbs: BREADCRUMBS.PROFILE,
        requiresAuth: true,
      },
    },
    {
      path: "/confirm",
      name: "confirm",
      meta: {
        layout: "ProductLayout",
        title: "Подтверждение действия",
      },
      component: () => import("@/views/ConfirmView.vue"),
    },
    {
      path: "/redirect",
      name: "redirect",
      meta: {
        layout: "ProductLayout",
        title: "Редирект",
      },
      component: () => import("@/views/RedirectView.vue"),
    },
    {
      path: "*",
      name: "404",
      meta: {
        layout: "EmptyLayout",
        title: "Страница не найдена",
      },
      component: () => import("@/views/NotFound.vue"),
    }
  ]
});

/* page title */
const DEFAULT_TITLE =
  "AtisTrade - онлайн-платформа для стоматологий и зуботехнических лабораторий";
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to?.meta?.title
      ? `${to.meta.title} | ${DEFAULT_TITLE}`
      : DEFAULT_TITLE;
  });
});

/* auth middleware */
router.beforeEach((to, from, next) => {
  // if route requires auth, check if user is logged in
  // and redirect to login page when needed
  scrollToTop();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const isLoggedIn = hasCookie("auth");
    if (!isLoggedIn) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
