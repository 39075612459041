<template>
  <component :is="currentLayout">
    <v-slide-x-transition mode="out-in">
      <router-view></router-view>
    </v-slide-x-transition>
  </component>
</template>
<script>
export default {
  name: 'LayoutBroker',
  props: {
    layouts: {
      type: Object
    },
    current: {
      type: String
    }
  },
  computed: {
    /**
     * Return layout component name for current route
     * @return {string}
     */
    currentLayout() {
      return this.layouts[this.current]
    }
  }
}
</script>