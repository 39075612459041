<template>
  <v-app app>
    <TopBar elevated @registration="registrationDrawer = !registrationDrawer" @login="loginDrawer = !loginDrawer"></TopBar>

    <div class="mb-10"></div>
    <div class="mb-md-10"></div>

    <v-main>
      <slot></slot>
    </v-main>

    <RegistrationDrawer  @login="swapToLogin()" :visible.sync="registrationDrawer"></RegistrationDrawer>
    <LoginDrawer @forgot="swapToForgot()" @register="swapToRegister" :visible.sync="loginDrawer"></LoginDrawer>
    <ForgotPasswordDrawer ref="forgot-password" @login="swapToLogin()" :visible.sync="forgotDrawer"></ForgotPasswordDrawer>

    <Alert ref="alert"></Alert>
    <ErrorDialog
      ref="errorDialog"
      :title="dialogs.error.title"
      :text="dialogs.error.text"
      :errors="dialogs.error.errors"
      :ok="dialogs.error.ok"
      :callback="dialogs.error.callback"
    ></ErrorDialog>
    <ConfirmationDialog
      ref="confirmDialog"
      :title="dialogs.confirm.title"
      :text="dialogs.confirm.text"
      :ok-text="dialogs.confirm.okButton"
      :callback="dialogs.confirm.callback"
      :cancel-text="dialogs.confirm.cancelButton"
      isCancelCbk
    ></ConfirmationDialog>
  </v-app>
</template>

<script>
import TopBar from "@/components/TopBar";
import RegistrationDrawer from "@/components/Auth/Registration/RegistrationDrawer"
import LoginDrawer from "@/components/Auth/Authorization/LoginDrawer"
import ForgotPasswordDrawer from "@/components/Auth/ForgotPassword/ForgotPasswordDrawer";
import Alert from "@/components/Alert";
import ErrorDialog from "@/components/ErrorDialog";
import ConfirmationDialog from "@/components/ConfirmationDialog";

export default {

  name: "ProductLayout",

  components: {
    ErrorDialog,
    ConfirmationDialog,
    // NavBar,
    TopBar,
    RegistrationDrawer,
    LoginDrawer,
    ForgotPasswordDrawer,
    Alert
  },

  beforeMount() {
    switch (this.$route.meta.drawer) {
      case 'login':
        this.swapToLogin();
        break;
      case 'register':
        this.registrationDrawer = true;
        break;
    }
    switch (this.$route.params.form) {
      case 'login':
        this.swapToLogin();
        break;
    }
    if (this.$route.params.isResetPassword) {
      this.swapToForgot()
    }
  },

  data() {
    return {
      loginDrawer: false,
      registrationDrawer: false,
      forgotDrawer: false,
      dialogs: {
        error: {
          text: undefined,
          title: undefined,
          errors: undefined,
          ok: undefined,
          callback: undefined,
        },
        confirm: {
          text: undefined,
          title: undefined,
          callback: undefined,
          okButton: undefined,
          cancelButton: undefined,
        },
      }
    }
  },

  provide() {
    return {
      alert: this.showAlert,
      showLoginForm: this.swapToLogin,
      showRegisterForm: this.swapToRegister,
      showError: this.showError,
      confirm: this.confirm,
    }
  },

  methods: {
    swapToRegister() {
      this.registrationDrawer = true;
      this.loginDrawer = false;
      this.forgotDrawer = false;
    },

    swapToForgot() {
      this.registrationDrawer = false;
      this.loginDrawer = false;
      this.forgotDrawer = true;
      this.$refs['forgot-password'].show();
    },

    swapToLogin() {
      this.registrationDrawer = false;
      this.loginDrawer = true;
      this.forgotDrawer = false;
    },

    showError(title, params = {}) {
      return new Promise((resolve) => {
        this.dialogs.error = {
          title: title,
          text: params.text,
          ok: params.ok,
          errors: params.errors,
          callback: resolve
        };
        this.$refs.errorDialog.show();
      });
    },

    /** @return Promise */
    confirm(title, params = {}) {
      if (typeof params === "string") {
        params = { text: params };
      }

      return new Promise((resolve) => {
        this.dialogs.confirm = {
          title: title,
          text: params.text,
          okButton: params.okButtonText,
          cancelButton: params.cancelButtonText,
          callback: resolve,
        };
        this.$refs.confirmDialog.show();
      });
    },

    showAlert(title, text) {
      this.$refs.alert.show(title, text);
    },
  }
};
</script>

<style scoped>

</style>
