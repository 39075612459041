import {alpha, alpha_spaces, digits, double, email, image, integer, length, max, max_value, min, min_value, oneOf, regex, required} from 'vee-validate/dist/rules';
import { checkEmailUnique, checkPhoneUnique } from '@/services/api';
import { extend, localize } from 'vee-validate';

import ru from 'vee-validate/dist/locale/ru.json';

localize('ru', ru);

localize('ru', {
    fields: {
        'email': {
            required: "Укажите e-mail",
            email: "Поле e-mail должно быть действительным электронным адресом"
        },
        'phone': {
            required: "Укажите номер телефона",
            phone: "Телефон указан в неверном формате (должен начинаться с \"+7\" и содержать 11 цифр)"
        },
        'individual-name': {
            required: "Укажите ФИО",
            cyrillic: "ФИО должно быть написано кириллицей"
        },
        'full-name': {
            required: "Укажите ФИО",
            cyrillic: "ФИО должно быть написано кириллицей",
            regex: "ФИО указаны в неверном формате",
            max: "Слишком длинное значение"
        },
        'inn': {
            required: "Поле ИНН обязательно для заполнения"
        },
        'snils': {
            required: "Необходимо указать номер СНИЛС"
        },
        'passport-series': {
            required: "Укажите серию паспорта"
        },
        'passport-number': {
            required: "Укажите номер паспорта"
        },
        'passport-birthday': {
            required: "Укажите дату рождения"
        },
        'passport-issue-authority': {
            required: "Укажите кем выдан паспорт"
        },
        'passport-issue-date': {
            required: "Укажите дату выдачи паспорта"
        },
        'passport-subdivision-code': {
            required: "Укажите код подразделения"
        },
        'passport-place-of-birth': {
            required: "Укажите место рождения как в паспорте"
        }
    }
});

extend('required', { ...required });
extend('email', { ...email });
extend('image', { ...image });
extend('max', { ...max });
extend('min', { ...min });
extend('lengths', { ...length, message: (_, placeholders) => `Кол-во символов должно быть ${placeholders.length}` });
extend('digits', { ...digits });
extend('regex', { ...regex });
extend('max_value', { ...max_value, message: (field, placeholders) => `Значение должно быть меньше чем ${placeholders.max}`});
extend('min_value', {...min_value});
extend('max_bonus', { ...max_value, message: () => 'Введенная сумма превышает Баланс Организации' });
extend('max_bonus_value', { ...max_value, message: () => 'Указано больше бонусов, чем есть в кошельке' });
extend('max_balance_value', { ...max_value, message: () => 'Указано больше рублей, чем есть в кошельке' });
extend('integer', { ...integer, message: () => 'Значение должно быть целым числом' });
extend('double', { ...double });
extend('oneOf', { ...oneOf });
extend('alpha', { ...alpha });
extend('alpha_spaces', { ...alpha_spaces });
// eslint-disable-next-line
extend('url', {
  validate(value) {
    if (value) {
      // eslint-disable-next-line
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?(?:[а-яА-ЯёЁ0-9]+([\-\.]{1}[а-яА-ЯёЁ0-9]+)*\.[а-яА-ЯёЁ]{2,5}(:[0-9]{1,5})?(\/.*)?|[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?)$/.test(value);
    }

    return false;
  },
  message: 'Введите корректный URL',
})

extend('required-object', {
  validate: (value) => {
    if (!value || typeof value !== 'object') {
      return false;
    }

    return Object.values(value).some((v) => !!v);
  },
  message: (field) => `Поле ${field} является обязательным`,
})

extend('cyrillic', {
    validate: (value) => {
        return /^[а-яёА-ЯЁ\-\s]+$/u.test(value);
    },
    message: () => `Допустимы только символы кириллицы`
});

extend('latin', {
  validate: (value) => {
    const latin = /^[a-zA-Z0-9.@\-_+ ]+$/u.test(value);
    return latin;
  },
  message: () => "Допустимы только символы латиницы"
})

extend('cyrillicOrLatin', {
  validate: (value) => {
      const cyrillic = /^[А-Яа-яЁё\-\s]+$/u.test(value);
      const latin = /^[a-zA-Z\-\s]+$/u.test(value);
      return cyrillic || latin;
  },
  message: () => "Допустимы символы только киллицы или только латиницы"
});

extend('phone', {
    validate: (value) => {
        const filteredValue = value.replace(/[^+0-9]+/g, ''); // keep only '+' and digits
        return /^\+7\d{10}$/.test(filteredValue);
    },
    message: 'Телефон указан в неверном формате (должен начинаться с "+7" и содержать 11 цифр)'
});

extend('subdivision-code', {
  validate: (value) => {
    const isValidCode = /^[0-9\-\s]+$/.test(value);
    return isValidCode && value.length === 7;
  },
  message: 'Код подразделения должен содержать 6 цифр, разделённых дефисом'
});

extend('phone_unique', {
    validate: async (value) => {
        try {
            return await checkPhoneUnique(value);
        } catch (error) {
            return error.details.fields.phone;
        }
    },
    message: 'Пользователь с этим номером телефона уже зарегистрирован в системе. Попробуйте войти или обратитесь в техническую поддержку.',
    immediate: false
});

extend('email_unique', {
    validate: async (value) => {
        try {
            return await checkEmailUnique(value);
        } catch (error) {
            return error.details.fields.email;
        }
    },
    message: 'Пользователь с этим e-mail уже зарегистрирован в системе. Попробуйте войти или обратитесь в техническую поддержку.',
    immediate: false
});
